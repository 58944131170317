import React from 'react'
import { NavLink } from 'react-router-dom'
import Silder from './Silder'

const CustomWebsiteDesign = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/CustomWebsiteBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "50vh" }}>
                    <div className="container pt-5 pb-4">
                        <div className='row ps-lg-2'>
                            <h2 className='text-white' style={{ fontSize: "45px" }} >CUSTOM WEBSITE DESIGN</h2>
                            <h2 className='' style={{ fontSize: "40px", color: "#11b6e4", fontWeight: "700", letterSpacing: "3px" }} > FOR UK MANUFACTURERS </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-0" style={{ backgroundColor: "black" }} >
                    <div className="container-lg p-lg-3">
                        <div className="row p-lg-2 pt-4 mt-lg-5 d-flex justify-content-lg-center align-items-center FontFamilyMontSerret">
                            <div className="col-lg-8 col-md-8">
                                <div className=''>
                                    <h2 className='pb-2' style={{ fontSize: "2.3rem", color: "#fff", fontWeight: "700" }} >SPECIALIST INDUSTRIAL WEBSITE DESIGN</h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.15rem", color: "#11b6e4", fontWeight: "700", letterSpacing: "1px" }} > Focused on industrial, manufacturing & engineering sectors  </h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > Our objective from the onset was to become the UK’s leading website design and marketing agency for UK manufacturing, engineering and B2B industrial sectors. The AMI team of highly proficient website designers, marketers, and creative personnel brings decades of experience in working closely alongside numerous engineering and manufacturing companies throughout the UK. </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }}> Our custom industrial websites are designed and developed to the highest standards, with excellent functionality, speed, responsiveness, navigation, and stunning appearance, helping communicate your company message faster. Our specialist area is building websites for engineering, electronics, plastics, general manufacturing and industrial sectors. </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }}> The aim we have for all our website customers is to build sites that will not only drive up the amount of relevant traffic, but increase the quality and quantity of enquiries. AMI has a proven track record in producing affordable, quality manufacturing websites that help generate new business. </p>

                                </div>

                                <div className='row  g-4 pb-4 mt-4 pt-4 '>
                                    <div className='col-lg-3 col-md-6'>
                                        <NavLink className='' to="/latestvideo">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > LATEST VIDEO </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-3 col-md-6'>
                                        <NavLink className='' to="/contact">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3 ' style={{ letterSpacing: "1px" }} > CONTACT US </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='row d-flex justify-content-around align-items-center FontFamilyMontSerret'>
                                    <div className='p-3' style={{ width: "18.75rem", color: "#fff", fontWeight: "700", border: "1.25rem solid #11b6e4", borderImage: "linear-gradient(45deg,#11b6e4,#11b6e4 3.75rem,transparent 3.75rem,transparent 5rem,#11b6e4 3.75rem) 12" }} >
                                        <h2 className='' style={{ fontSize: "1.02rem", lineHeight: "25px" }} >Every company needs a Ronnie! Having worked with AMI for the best part of a year, I can thoroughly recommend them if you’re a manufacturing /engineering company looking for a new website. </h2>
                                    </div>
                                    <div className='mt-3 text-center ps-lg-5 ms-lg-5' style={{ lineHeight: "1" }} >
                                        <h2 className='ps-lg-5' style={{ fontSize: "1rem", color: "#fff", fontWeight: "700", lineHeight: ".8" }} >Amanda Dakin</h2>
                                        <h2 className='ps-lg-5' style={{ fontSize: ".725rem", color: "#fff", fontWeight: "700", lineHeight: "1.3" }} >The Shearline Group</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container-fluid d-sm-none d-block " style={{ backgroundColor: "black" }} >
                    <div className="container p-lg-3 pb-lg-5">
                        <div className="row p-2 d-flex justify-content-center align-items-center">
                            <div className="col-lg-7 pt-lg-5 ">
                                <div className='pt-lg-5 mt-lg-5'>
                                    <h2 className='pb-2' style={{ fontSize: "2.675rem", color: "#fff" }} > INDIA'S LEADING INDUSTRIAL MARKETING MANAGEMENT AGENCY </h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700" }} >Promoting INDIA engineering, manufacturing & industrial companies</h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9475rem", color: "#fff", lineHeight: "1.375" }} >ICLOUD – Marketing for Manufacturing is the INDIA’s leading industrial marketing management agency specialising in helping manufacturers and engineers gain more enquiries online. Over the years the ICLOUD team has acquired a wealth of knowledge working with hundreds of industrial companies. We understand the most effective ways to market companies in the industrial sector – from affordable website development to promotional video and search marketing that delivers new business enquiries – and have an extensive track record and a growing base of very happy customers.</p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9575rem", color: "#fff", lineHeight: "1.375" }}>We are trusted by INDIA engineers, manufacturers and industrial businesses of all sizes and budgets, helping them to successfully promote their businesses online. We offer a comprehensive and affordable outsourced solution, with outstanding customer service that delivers on its promises. ICLOUD – Marketing for Manufacturing, your one-stop shop for all your industrial marketing needs.</p>
                                </div>

                                <div className='row g-4 pb-4 mt-3 pt-3'>
                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > INSIDE ICLOUD </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > TESTIMONIALS </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > LATEST WEBSITES </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block" style={{ backgroundColor: "black" }} >
                    <div className=" p-lg-3 ">
                        <div className="row mb-5 d-flex justify-content-between align-items-center FontFamilyMontSerret">
                            <div className="col-lg-6">
                                <div className=' mt-lg-5 ps-lg-5 ms-lg-5'>
                                    <h2 className='pb-2 FontFamilyMontSerret' style={{ fontSize: "2.275rem", color: "#fff", fontWeight: "800", letterSpacing: "2px" }} > SEE HOW WE WORK! </h2>
                                    <h3 className='mt-2 pb-2 FontFamilyMontSerret' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700", letterSpacing: "1px" }} > Case Study: The Shearline Group </h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > The Shearline Group approached us with a dilemma – ’How can we tie our businesses together, whilst keeping them individual?’. </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > The Shearline Group encapsulate CNC Machining, Metal Fabrication and Laser Cutting within 3 arms; Shearline, ShearXL and HLT. </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > A full manufacturing solution with remarkable facilities in Ely, Cambridgeshire. They’re also celebrating 50 years in business. </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > Our job was to present the businesses digitally, all 3 subsidiaries that sit under the Group umbrella. We then put the cherry on the cake with a Group website which created a portal linking to each company. </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > Learn more on the process by watching the short video below, as you hear from Ronnie who lead the project. </p>
                                    <div className='pt-lg-4'>
                                        <NavLink className='d-flex justify-content-start align-items-center text-decoration-none' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3 ' style={{ letterSpacing: "1px" }} > WEBSITES </b> </button>
                                        </NavLink>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-6">

                                <div className=''>
                                    <img className='w-100' style={{ height: "25rem" }} src={require("../img/CustomWebsite1.png")} alt="emp1" title='emp-1' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row flex">
                        <div className="col-lg-3 col-md-6 p-0">
                            <div className="card">
                                <img src={require("../img/2col5.png")} className='' alt="" />
                                <div className="intro">
                                    <h1>VIDEO</h1>
                                    <h6>Over 10 years visiting, filming and understanding many manufacturing processes. Using the latest 4K cameras, we will display your capabilities affordably and professionally. </h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <div className="card">
                                <img src={require("../img/2col6.png")} className='' alt="" />
                                <div className="intro">
                                    <h1>CREATING DESIGN</h1>
                                    <h6>Dedicated to manufacturing, our creative designers will take your concepts and develop them into an impressive end product that captures your target market.</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <div className="card">
                                <img src={require("../img/2col7.png")} className='' alt="" />
                                <div className="intro">
                                    <h1>PAID ADS MANAGEMENT</h1>
                                    <h6>As Google partners, AMI offers full Ad management that complements organic rankings. We manage highly effective industrial campaigns on affordable budgets, set up to increase quality enquiries.</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <div className="card">
                                <img src={require("../img/2col8.png")} className='' alt="" />
                                <div className="intro">
                                    <h1>CONTENT WRITING</h1>
                                    <h6>Our team consists of experienced copywriters who have obtained a wealth of in-depth knowledge within the engineering and manufacturing sectors.</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block" style={{ borderTop: "0.375rem solid #11b6e4" }} >
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-lg-8">
                            <div className="p-lg-5   ">
                                <h2 className='ps-lg-5 ms-lg-4 mb-4' style={{ color: "#11b6e4", fontSize: "2.8rem", fontWeight: "800" }} >SUPPORTING INDIA ENGINEERING</h2>
                                <p className='ps-lg-5 ms-lg-4 FONT' style={{ fontSize: "1.5rem", fontWeight: "600" }} >Dedicated to maximising exposure for UK Heavy & Large Part Machinists</p>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0">
                            <img className='w-100 ' style={{ height: "23rem" }} src={require("../img/last1.png")} alt="emp1" title='emp-1' />
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default CustomWebsiteDesign