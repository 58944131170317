import React from 'react'

const AcceptableUsePolicy = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/TearmUseBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "30vh" }}>
                    <div className="container pt-5 pb-4">
                        <div className='row ps-lg-5 ms-lg-3'>
                            <h2 className='mt-lg-3 ms-lg-5' style={{ fontSize: "45px", color: "#11b6e4", fontWeight: "700", letterSpacing: "3px" }} > ACCEPTABLE USE POLICY </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-lg-5" style={{ backgroundColor: "#000" }} >
                    <div className="container-lg p-lg-3 pt-3 text-white">
                        <div className="row d-flex justify-content-center p-lg-4 FontFamilyMontSerret">
                            <div className='col-lg-10'  >
                                <div className='' style={{ fontSize: ".8975rem" }} >
                                    <p> This acceptable use policy sets out the terms between you and us under which you may access our website https://www.amiweb.co.uk/ (our site). This acceptable use policy applies to all users of, and visitors to, our site. </p>
                                    <p> Your use of our site means that you accept, and agree to abide by, all the policies in this acceptable use policy, which supplement our terms of website use https://amiweb.co.uk/terms-of-use/ </p>
                                    <p> https://www.amiweb.co.uk/terms-of-use/ is a site operated by Aspect Media International Ltd (we or us).  We are registered in England and Wales under company number 06923263 and we have our registered office at 19 Meadow Way, Bradley Stoke, Bristol, BS32 8BN.  Our main trading address is Suite 201D, E & F Queens House, Queens Street, Barnstaple, EX328HJ. Our VAT number is 134545912. </p>
                                    <p className='mb-lg-4 mt-lg-4' style={{ fontSize: "14px", fontWeight: "800" }}> Prohibited uses </p>
                                    <p className='mb-lg-4 mt-lg-4' > You may use our site only for lawful purposes. You may not use our site:</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; In any way that breaches any applicable local, national or international law or regulation.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; For the purpose of harming or attempting to harm minors in any way.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</p>

                                    <p className='mb-lg-4 mt-lg-4' style={{ fontSize: "14px", fontWeight: "800" }}> You also agree: </p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Not to reproduce, duplicate, copy or re-sell any part of our site in contravention of the provisions of our terms of website use https://www.amiweb.co.uk/terms-of-use/</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Not to access without authority, interfere with, damage or disrupt:</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; any part of our site;</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; any equipment or network on which our site is stored;</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; any software used in the provision of our site; or</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; any equipment or network or software owned or used by any third party.</p>

                                    <p className='mb-lg-4 mt-lg-4' style={{ fontSize: "14px", fontWeight: "800" }}> Interactive services </p>
                                    <p className='mb-lg-4 mt-lg-4' > We may from time to time provide interactive services on our site, including, without limitation:</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Chat rooms.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Bulletin boards.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Blogs</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Competitions</p>

                                    <p> Where we do provide any interactive service, we will provide clear information to you about the kind of service offered, if it is moderated and what form of moderation is used (including whether it is human or technical). </p>
                                    <p> We will do our best to assess any possible risks for users (and in particular, for children) from third parties when they use any interactive service provided on our site, and we will decide in each case whether it is appropriate to use moderation of the relevant service (including what kind of moderation to use) in the light of those risks. However, we are under no obligation to oversee, monitor or moderate any interactive service we provide on our site, and we expressly exclude our liability for any loss or damage arising from the use of any interactive service by a user in contravention of our content standards, whether the service is moderated or not. </p>
                                    <p> The use of any of our interactive services by a minor is subject to the consent of their parent or guardian. We advise parents who permit their children to use an interactive service that it is important that they communicate with their children about their safety online, as moderation is not foolproof. Minors who are using any interactive service should be made aware of the potential risks to them. </p>
                                    <p> Where we do moderate an interactive service, we will normally provide you with a means of contacting the moderator, should a concern or difficulty arise. </p>
                                    <p> Content standards </p>
                                    <p> These content standards apply to any and all material which you contribute to our site (contributions), and to any interactive services associated with it. </p>
                                    <p> You must comply with the spirit and the letter of the following standards. The standards apply to each part of any contribution as well as to its whole. </p>

                                    <p className='mb-lg-4 mt-lg-4' > Contributions must:</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Be accurate (where they state facts).</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Be genuinely held (where they state opinions).</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Comply with applicable law in the UK and in any country from which they are posted. </p>

                                    <p className='mb-lg-4 mt-lg-4' > Contributions must:</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Contain any material which is defamatory of any person.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Contain any material which is obscene, offensive, hateful or inflammatory.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Promote sexually explicit material. </p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Promote violence.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Infringe any copyright, database right or trade mark of any other person. </p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Be likely to deceive any person.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Promote any illegal activity. </p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Be threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Be likely to harass, upset, embarrass, alarm or annoy any other person.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Be used to impersonate any person, or to misrepresent your identity or affiliation with any person. </p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Give the impression that they emanate from us, if this is not the case. </p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse. </p>

                                    <p><b> Suspension and termination </b></p>
                                    <p> We will determine, in our discretion, whether there has been a breach of this acceptable use policy through your use of our site.  When a breach of this policy has occurred, we may take such action as we deem appropriate. </p>
                                    <p> Failure to comply with this acceptable use policy constitutes a material breach of the terms of use https://www.amiweb.co.uk/terms-of-use/ upon which you are permitted to use our site, and may result in our taking all or any of the following actions: </p>

                                    <p> <i class="fa-sharp fa-solid fa-angle-right mt-lg-4" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Immediate, temporary or permanent withdrawal of your right to use our site. </p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Immediate, temporary or permanent removal of any posting or material uploaded by you to our site.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Issue of a warning to you. </p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Further legal action against you.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Disclosure of such information to law enforcement authorities as we reasonably feel is necessary.</p>

                                    <p> We exclude liability for actions taken in response to breaches of this acceptable use policy.  The responses described in this policy are not limited, and we may take any other action we reasonably deem appropriate. </p>
                                    <p><b> Changes to the acceptable use policy </b></p>
                                    <p>We may revise this acceptable use policy at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we make, as they are legally binding on you. Some of the provisions contained in this acceptable use policy may also be superseded by provisions or notices published elsewhere on our site.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AcceptableUsePolicy