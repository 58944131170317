import React from 'react'
import { NavLink } from 'react-router-dom'
import Silder from './Silder'

const CreativeDesign = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/CreativeDesignBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "50vh" }}>
                    <div className="container pt-5 pb-4">
                        <div className='row ps-lg-2'>
                            <h2 className='text-white' style={{ fontSize: "45px" }} >CREATIVE DESIGN</h2>
                            <h2 className='' style={{ fontSize: "40px", color: "#11b6e4", fontWeight: "700", letterSpacing: "3px" }} > FOR MANUFACTURING </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-0" style={{ backgroundColor: "black" }} >
                    <div className="container-lg p-lg-3">
                        <div className="row p-lg-2 pt-4 mt-lg-5 d-flex justify-content-lg-center align-items-center FontFamilyMontSerret">
                            <div className="col-lg-7 col-md-7">
                                <div className=''>
                                    <h2 className='pb-2' style={{ fontSize: "2.3rem", color: "#fff", fontWeight: "700" }} >CREATIVE & GRAPHIC DESIGN</h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.10rem", color: "#11b6e4", fontWeight: "700", letterSpacing: "1px" }} > Experienced designers specialising in the industry and manufacturing markets </h3>
                                    <p className='pb-2 mt-4' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > AMI combines creativity, experience and good old-fashioned intuition for clear communication across a multitude of media platforms, such as social media, print, Google Ads, online publications, video sharing and more. </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }}> Our creative designers can take your ‘bare bones’ concepts and develop them into an impressive end product that you will be delighted with. Our creative team has the added benefit of working alongside our in-house video production and photography specialists. The AMI team works hard to make our clients’ branding and marketing aspirations a success. Our creative designers can help inspire, give credibility and ignite interest for your company and services. </p>
                                </div>

                                <div className='mt-4'>
                                    <h2 className='mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: ".675" }}> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11B6E4", fontSize: "18px" }} ></i> &nbsp; Digital Brochure Design </h2>
                                    <h2 className='mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: ".675" }}> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11B6E4", fontSize: "18px" }} ></i> &nbsp; Video Brochure Design </h2>
                                    <h2 className='mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: ".675" }}> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11B6E4", fontSize: "18px" }} ></i> &nbsp; Exhibition Stand Design </h2>
                                    <h2 className='mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: ".675" }}> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11B6E4", fontSize: "18px" }} ></i> &nbsp; Logo Design </h2>
                                    <h2 className='mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: ".675" }}> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11B6E4", fontSize: "18px" }} ></i> &nbsp; Magazine Publication Design </h2>
                                    <h2 className='mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: ".675" }}> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11B6E4", fontSize: "18px" }} ></i> &nbsp; Email Campaign Design </h2>
                                </div>

                                <div className='row  g-4 pb-4 mt-4 pt-4 '>
                                    <div className='col-lg-3 col-md-6'>
                                        <NavLink className='' to="/contact">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > CONTACT US </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-6 col-md-6'>
                                        <NavLink className='' to="/customwebsitedesign">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > WEBSITE DESIGN </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-5">
                                <div className=''>
                                    <img className='w-100' style={{ height: "27rem" }} src={require("../img/CreativeDesign1.png")} alt="emp1" title='emp-1' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container-fluid d-sm-none d-block " style={{ backgroundColor: "black" }} >
                    <div className="container p-lg-3 pb-lg-5">
                        <div className="row p-2 d-flex justify-content-center align-items-center">
                            <div className="col-lg-7 pt-lg-5 ">
                                <div className='pt-lg-5 mt-lg-5'>
                                    <h2 className='pb-2' style={{ fontSize: "2.675rem", color: "#fff" }} > INDIA'S LEADING INDUSTRIAL MARKETING MANAGEMENT AGENCY </h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700" }} >Promoting INDIA engineering, manufacturing & industrial companies</h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9475rem", color: "#fff", lineHeight: "1.375" }} >ICLOUD – Marketing for Manufacturing is the INDIA’s leading industrial marketing management agency specialising in helping manufacturers and engineers gain more enquiries online. Over the years the ICLOUD team has acquired a wealth of knowledge working with hundreds of industrial companies. We understand the most effective ways to market companies in the industrial sector – from affordable website development to promotional video and search marketing that delivers new business enquiries – and have an extensive track record and a growing base of very happy customers.</p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9575rem", color: "#fff", lineHeight: "1.375" }}>We are trusted by INDIA engineers, manufacturers and industrial businesses of all sizes and budgets, helping them to successfully promote their businesses online. We offer a comprehensive and affordable outsourced solution, with outstanding customer service that delivers on its promises. ICLOUD – Marketing for Manufacturing, your one-stop shop for all your industrial marketing needs.</p>
                                </div>

                                <div className='row g-4 pb-4 mt-3 pt-3'>
                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > INSIDE ICLOUD </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > TESTIMONIALS </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > LATEST WEBSITES </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row flex">
                        <div className="col-lg-3 col-md-6 p-0">
                            <NavLink className='text-decoration-none' to='/seo'>
                                <div className="card">
                                    <img src={require("../img/2col2.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>SEO</h1>
                                        <h6>We manage highly effective campaigns on affordable budgets, set up to increase quality enquiries for UK manufacturers</h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <NavLink className='text-decoration-none' to='/customwebsitedesign'>
                                <div className="card">
                                    <img src={require("../img/2col1.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>WEBSITE DESIGN</h1>
                                        <h6>We have a proven track record designing affordable, custom-built and responsive industrial websites that generate enquiries </h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <NavLink className='text-decoration-none' to='/tracking'>
                                <div className="card">
                                    <img src={require("../img/2col4.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>COMPANY NAME TRACKING</h1>
                                        <h6>We provide a sophisticated IP tracking software that identifies company names visiting your website</h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <NavLink className='text-decoration-none' to='/latestvideo'>
                                <div className="card">
                                    <img src={require("../img/2col5.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>VIDEO</h1>
                                        <h6>10+ years filming experience of many varying manufacturing processes. Affordable and professional videography</h6>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block" style={{ borderTop: "0.375rem solid #11b6e4" }} >
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-lg-8">
                            <div className="p-lg-5   ">
                                <h2 className='ps-lg-5 ms-lg-4 mb-4' style={{ color: "#11b6e4", fontSize: "2.8rem", fontWeight: "800" }} >SUPPORTING INDIA ENGINEERING</h2>
                                <p className='ps-lg-5 ms-lg-4 FONT' style={{ fontSize: "1.5rem", fontWeight: "600" }} >Dedicated to maximising exposure for UK Heavy & Large Part Machinists</p>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0">
                            <img className='w-100 ' style={{ height: "23rem" }} src={require("../img/last1.png")} alt="emp1" title='emp-1' />
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default CreativeDesign