import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import Routing from './Commponents/Routing';



const App = () => {
  return (
    <>
      <React.StrictMode>
        <BrowserRouter>
          <Routing/>
        </BrowserRouter>
      </React.StrictMode>
    </>
  );
};

export default App