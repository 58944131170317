import React from 'react'
import { NavLink } from 'react-router-dom'
import "./MetalPressing.css"
import Silder from './Silder'

const MetalPressing = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/MPBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "50vh" }}>
                    <div className="container pt-5 pb-4">
                        <div className='row ps-lg-2'>
                            <h2 className='text-white' style={{ fontSize: "45px" }} >INDUSTRIES</h2>
                            <h2 className='' style={{ fontSize: "40px", color: "#11b6e4", fontWeight: "700", letterSpacing: "3px" }} > METAL PRESSING </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-0 " style={{ backgroundColor: "black" }} >
                    <div className="container-lg p-lg-3">
                        <div className="row p-lg-2 pt-4 mt-lg-5 d-flex justify-content-lg-center align-items-center FontFamilyMontSerret">
                            <div className="col-lg-8 col-md-8">
                                <div className=''>
                                    <h2 className='pb-2' style={{ fontSize: "2.3rem", color: "#fff", fontWeight: "700" }} > MARKETING FOR METAL PRESSWORKERS </h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.15rem", color: "#11b6e4", fontWeight: "700", letterSpacing: "1px" }} > Effective digital marketing to increase inbound subcontract enquiries  </h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > In today’s challenging manufacturing environment, ensuring your services and capabilities can be found online is essential. AMI is the UK’s leading digital marketing agency for manufacturing and engineering companies nationwide. We have over 10 years of experience in working with businesses in the metal pressings sector, catering for our wide range of customers and targeting industries such as automotive, aerospace, commercial vehicles, white goods, DIY and more.</p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }}> As a dedicated manufacturing marketing agency, we have an in-depth understanding of the terminology and processes within the metal presswork sector. Our strategic marketing campaigns reflect the technical capability of our clients’ services, which ensures the quality of enquiries for our customers is of the highest standard. </p>
                                </div>

                                <div className='row  g-4 pb-4 mt-4 pt-4 '>
                                    <div className='col-lg-3 col-md-6'>
                                        <NavLink className='' to="/seo">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3 ' style={{ letterSpacing: "1px" }} > SEO </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-6 col-md-6'>
                                        <NavLink className='' to="/websitemanagement">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > WEBSITE MANAGEMENT </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='row d-flex justify-content-around align-items-center FontFamilyMontSerret'>
                                    <div className='p-3' style={{ width: "18.75rem", color: "#fff", fontWeight: "700", border: "1.25rem solid #11b6e4", borderImage: "linear-gradient(45deg,#11b6e4,#11b6e4 3.75rem,transparent 3.75rem,transparent 5rem,#11b6e4 3.75rem) 12" }} >
                                        <h2 className='' style={{ fontSize: "1.02rem", lineHeight: "25px" }} > Our online enquiry levels have risen significantly. Not only that, but the type of enquiries we are now getting are far more targeted. </h2>
                                    </div>
                                    <div className='mt-3 text-center ps-lg-5 ms-lg-5' style={{ lineHeight: "1" }} >
                                        <h2 className='ps-lg-5' style={{ fontSize: "1rem", color: "#fff", fontWeight: "700", lineHeight: ".8" }} >Robert Bowler</h2>
                                        <h2 className='ps-lg-5' style={{ fontSize: ".725rem", color: "#fff", fontWeight: "700", lineHeight: "1.3" }} >David Bowler & Sons Ltd</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container-fluid d-sm-none d-block " style={{ backgroundColor: "black" }} >
                    <div className="container p-lg-3 pb-lg-5">
                        <div className="row p-2 d-flex justify-content-center align-items-center">
                            <div className="col-lg-7 pt-lg-5 ">
                                <div className='pt-lg-5 mt-lg-5'>
                                    <h2 className='pb-2' style={{ fontSize: "2.675rem", color: "#fff" }} > INDIA'S LEADING INDUSTRIAL MARKETING MANAGEMENT AGENCY </h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700" }} >Promoting INDIA engineering, manufacturing & industrial companies</h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9475rem", color: "#fff", lineHeight: "1.375" }} >ICLOUD – Marketing for Manufacturing is the INDIA’s leading industrial marketing management agency specialising in helping manufacturers and engineers gain more enquiries online. Over the years the ICLOUD team has acquired a wealth of knowledge working with hundreds of industrial companies. We understand the most effective ways to market companies in the industrial sector – from affordable website development to promotional video and search marketing that delivers new business enquiries – and have an extensive track record and a growing base of very happy customers.</p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9575rem", color: "#fff", lineHeight: "1.375" }}>We are trusted by INDIA engineers, manufacturers and industrial businesses of all sizes and budgets, helping them to successfully promote their businesses online. We offer a comprehensive and affordable outsourced solution, with outstanding customer service that delivers on its promises. ICLOUD – Marketing for Manufacturing, your one-stop shop for all your industrial marketing needs.</p>
                                </div>

                                <div className='row g-4 pb-4 mt-3 pt-3'>
                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > INSIDE ICLOUD </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > TESTIMONIALS </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > LATEST WEBSITES </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block" style={{ backgroundColor: "black" }} >
                    <div className=" p-lg-3 ">
                        <div className="row mb-5 d-flex justify-content-between align-items-center FontFamilyMontSerret">
                            <div className="col-lg-7">
                                <div className=' mt-lg-5 ps-lg-5 ms-lg-5'>
                                    <h2 className='pb-2 FontFamilyMontSerret' style={{ fontSize: "2.275rem", color: "#fff", fontWeight: "800", letterSpacing: "2px" }} > CLIENT FOCUS </h2>
                                    <h3 className='mt-2 pb-2 FontFamilyMontSerret' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700", letterSpacing: "1px" }} > Frank Dudley Ltd </h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > Frank Dudley Limited is a family-owned metal presswork manufacturer located in the heart of the West Midlands. With a 57,000 sq. ft. facility, line of state-of-the-art presses and its experienced workforce, it is able to manufacture the highest quality metal pressings and welded assemblies to specification. </p>

                                    <div className='mb-1'>
                                        <i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i>
                                        <span className='ps-2 CLIENTFOCUS ' style={{ fontSize: ".8999rem", letterSpacing: "1px", fontWeight: "400", lineHeight: "1.675" }}> SEO</span>
                                    </div>
                                    <div className='mb-1'>
                                        <i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i>
                                        <span className='ps-2 CLIENTFOCUS' style={{ fontSize: ".8999rem", letterSpacing: "1px", fontWeight: "400", lineHeight: "1.675" }}> PPC</span>
                                    </div>

                                    <div className='mb-1'>
                                        <i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i>
                                        <span className='ps-2 CLIENTFOCUS' style={{ fontSize: ".8999rem", letterSpacing: "1px", fontWeight: "400", lineHeight: "1.675" }}> Company Tracking</span>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-5">

                                <div className='position-relative'>
                                    <img className='w-100' style={{ height: "25rem" }} src={require("../img/MP1.png")} alt="emp1" title='emp-1' />
                                    <div className='position-absolute  translate-middle' style={{ top: "53%", left: "40%" }} >
                                        <img className='w-100' style={{ height: "5rem" }} src={require("../img/MPclient.png")} alt="emp1" title='emp-1' />
                                        <div className='mt-4 pt-lg-4'>
                                            <NavLink className='d-flex justify-content-center align-items-center text-decoration-none' to="https://www.icloudsoftware.co.in/">
                                                <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3 ' style={{ letterSpacing: "1px" }} > WEBSITES </b> </button>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" >
                    <div className="row">
                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/MP2.png")} className='imageMP' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/MP3.png")} className='imageMP' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/MP4.png")} className='imageMP' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/MP5.png")} className='imageMP' alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block" style={{ borderTop: "0.375rem solid #11b6e4" }} >
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-lg-8">
                            <div className="p-lg-5   ">
                                <h2 className='ps-lg-5 ms-lg-4 mb-4' style={{ color: "#11b6e4", fontSize: "2.8rem", fontWeight: "800" }} >SUPPORTING INDIA ENGINEERING</h2>
                                <p className='ps-lg-5 ms-lg-4 FONT' style={{ fontSize: "1.5rem", fontWeight: "600" }} >Dedicated to maximising exposure for UK Heavy & Large Part Machinists</p>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0">
                            <img className='w-100 ' style={{ height: "23rem" }} src={require("../img/last1.png")} alt="emp1" title='emp-1' />
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>


        </>
    )
}

export default MetalPressing