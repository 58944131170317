import React from 'react'
import "./Footer.css"
import { NavLink } from 'react-router-dom'

const Footer = () => {

    return (
        <>

            <section>
                <div className="container-fluid p-0" style={{ backgroundImage: `url(${require("../img/footerbg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "90%", borderTop: ".375rem solid #11b6e4" }} >
                    <div className="container p-3">
                        <div className="row d-flex justify-content-between align-items-center p-2">
                            <div className="col-lg-8 col-md-8">
                                <div className="row pt-lg-3 mb-3">
                                    <div className="col-lg-4">
                                        <h2 className='' style={{ fontSize: "1rem", fontWeight: "600", color: "#11b6e4", borderBottom: ".0625rem solid #11b6e4", paddingBottom: "0.95rem", paddingTop: "0.65rem" }} >Digital</h2>
                                        <div className='pt-3'>
                                            <NavLink className='text-decoration-none' to='/seo'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> SEO </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/socialmedia'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Social Media </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/ppc'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> PPC </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/tracking'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Tracking & Analytics </span></h5>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <h2 className='' style={{ fontSize: "1rem", fontWeight: "600", color: "#11b6e4", borderBottom: ".0625rem solid #11b6e4", paddingBottom: "0.95rem", paddingTop: "0.65rem" }} > Websites </h2>
                                        <div className='pt-3'>
                                            <NavLink className='text-decoration-none' to='/customwebsitedesign'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Custom Website Design </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/websitemanagement'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Website Management </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/e-commerce'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> E-Commerce </span></h5>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <h2 className='' style={{ fontSize: "1rem", fontWeight: "600", color: "#11b6e4", borderBottom: ".0625rem solid #11b6e4", paddingBottom: "0.95rem", paddingTop: "0.65rem" }} > About us </h2>
                                        <div className='pt-3'>
                                            <NavLink className='text-decoration-none' to='/news'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> News </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/careers'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Careers </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/contact'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Contact us </span></h5>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>

                                <div className="row pt-lg-3">
                                    <div className="col-lg-4">
                                        <h2 className='' style={{ fontSize: "1rem", fontWeight: "600", color: "#11b6e4", borderBottom: ".0625rem solid #11b6e4", paddingBottom: "0.95rem", paddingTop: "0.65rem" }} > Creative </h2>
                                        <div className='pt-3'>
                                            <NavLink className='text-decoration-none' to='/contentwriting'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Content Writing </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/creativedesign'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Creative Design </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/brandevelopment'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Brand Development </span></h5>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <h2 className='' style={{ fontSize: "1rem", fontWeight: "600", color: "#11b6e4", borderBottom: ".0625rem solid #11b6e4", paddingBottom: "0.95rem", paddingTop: "0.65rem" }} > Visual </h2>
                                        <div className='pt-3'>
                                            <NavLink className='text-decoration-none' to='/companyvideo'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Company Video </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/socialmediavideo'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Social Media Video </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/promotionalvideo'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Promotional Video </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/videowithphotography'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Video with Photography </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/onlocation'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> On Site Industrial Photography </span></h5>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <h2 className='' style={{ fontSize: "1rem", fontWeight: "600", color: "#11b6e4", borderBottom: ".0625rem solid #11b6e4", paddingBottom: "0.95rem", paddingTop: "0.65rem" }} > Policies </h2>
                                        <div className='pt-3'>
                                            <NavLink className='text-decoration-none' to='/tearmuse'>
                                                <h5 className='mb-4 '><i class="fa-sharp fa-solid fa-angle-right  " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Terms of use </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/privacypolicy'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Privacy Policy </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/cookiepolicy'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Cookie Policy </span></h5>
                                            </NavLink>
                                            <NavLink className='text-decoration-none' to='/acceptableusepolicy'>
                                                <h5 className=' mb-4'><i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i><span className='ms-2 FOOTERHOVER'> Acceptable use policy </span></h5>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className='row justify-content-center align-items-center'>
                                    <img className='w-75' style={{ height: "10rem" }} src={require("../img/IndLOGO.png")} alt="icloudsoft_logo" />
                                    <div className='row justify-content-center align-items-center g-2'>
                                        <div className='col-lg-12  text-center'>
                                            <NavLink to="https://www.instagram.com/icloud_soft/" target="_blank"><i className="fa-brands fa-instagram fs-5 ms-3 SocialIcon " ></i></NavLink>
                                            <NavLink to='https://in.linkedin.com/in/anil-kadam-18b113147' target="_blank"><i className="fa-brands fa-linkedin fs-5 ms-3 SocialIcon"></i> </NavLink>
                                            <NavLink to='https://twitter.com/icloudsoftTech' target="_blank"><i className="fa-brands fa-twitter fs-5 ms-3 SocialIcon"></i> </NavLink>
                                            <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-facebook-f ms-3 fs-5  SocialIcon"></i></NavLink>
                                        </div>
                                    </div>
                                    <ul className='text-white mt-lg-3 pt-2  text-center' style={{ listStyleType: "none" }} >
                                        <li className='mb-2' style={{ color: "#11b6e4", fontSize: "16px", fontWeight: "600" }}> Email :  <span className='' style={{ fontWeight: "200", color: "#fff" }} > info@ami.com </span></li>
                                        <li className='mb-2' style={{ color: "#11b6e4", fontSize: "16px", fontWeight: "600" }} > Mo :  <span className='' style={{ fontWeight: "200", color: "#fff" }} >  +91 9999999999 </span></li>
                                    </ul>
                                    <div className='text-center'>
                                        <img className='w-50 ' style={{ height: "8rem" }} src={require("../img/google.png")} alt="icloudsoft_logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='text-lg-center p-3 text-white ' style={{ backgroundColor: "#060607" }}>
                    <h5 className='' style={{ fontSize: ".8125rem", color: "#11b6e4", fontWeight: "400" }} >Copyright © Aspect Media International | AMI - Marketing for Manufacturing™ | 2023 | Company Reg no. 06923263</h5>
                </div>
            </section>

        </>
    )
}

export default Footer