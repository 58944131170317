import React from 'react'
import { NavLink } from 'react-router-dom'

const CookiePolicy = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/TearmUseBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "30vh" }}>
                    <div className="container pt-5 pb-4">
                        <div className='row ps-lg-5 ms-lg-3'>
                            <h2 className='mt-lg-3 ms-lg-5' style={{ fontSize: "43px", color: "#11b6e4", fontWeight: "700", letterSpacing: "3px" }} > COOKIE POLICY </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-lg-5" style={{ backgroundColor: "#000" }} >
                    <div className="container-lg p-lg-3 pt-3 text-white">
                        <div className="row d-flex justify-content-center p-lg-4 FontFamilyMontSerret">
                            <div className='col-lg-10'  >
                                <div className='' style={{ fontSize: ".8975rem" }} >
                                    <p className='mb-lg-4 mt-lg-4' style={{ fontSize: "18px", fontWeight: "800" }}> INFORMATION ABOUT OUR USE OF COOKIES </p>
                                    <p> Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. By continuing to browse the site, you are agreeing to our use of cookies.</p>
                                    <p> A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer’s hard drive.</p>
                                    <p className='mb-lg-4 mt-lg-4' > We use the following cookies:</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; <b> Strictly necessary cookies – </b> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our  website, use a shopping cart or make use of e-billing services.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; <b> Analytical/performance cookies – </b> They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; <b> Functionality cookies – </b> These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; <b> Targeting cookies – </b> These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the content displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</p>
                                    <p className='mt-lg-4 pt-4'> Please note that third parties including, for example, providers of external services like web traffic analysis services may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p>
                                    <p> You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.</p>
                                    <p> Except for essential cookies, all cookies will expire after 14 days.</p>

                                </div>

                                <div className='mt-lg-4 pt-lg-4'>
                                    <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                        <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > CHANGE COOKIE PREFERENCES </b> </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CookiePolicy