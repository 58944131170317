import React from 'react'
import "./Icons.css"
import { NavLink } from 'react-router-dom'

const Icons = () => {
    return (
        <>

            <div className='sticky-container sticky-top'>
                <ul className='sticky text-white UbuntuFONTFAMILY'>
                    <NavLink target='_blank' to='tel:1234567891' >
                        <li className='lastItem firstItem'  >
                            <i class="fa-sharp fa-solid fa-phone "></i>
                        </li>
                    </NavLink>

                    <NavLink target='_blank' to='mailto:info@aim.co.in' >
                        <li className='lastItem firstItem'>
                            <i class="fa-solid fa-envelope text-center"></i>
                        </li>
                    </NavLink>
                </ul>
            </div>


        </>
    )
}

export default Icons