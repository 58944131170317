import React from 'react'
import "./Header.css"
import { NavLink } from "react-router-dom"

const Header = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0  d-none d-sm-block">
                    <nav className="navbar navbar-expand-lg navbar-dark " style={{ backgroundColor: "#030404", padding: ".655rem" }}>
                        <div className="container-lg ">
                            <div className=''>
                                <NavLink to="/">
                                    <img className='' style={{ height: "6rem" }} src={require("../img/IndLOGO.png")} alt="icloudsoft_logo" />
                                </NavLink>
                            </div>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav FONT d-flex justify-content-end ">
                                    <li className="nav-item pe-lg-2">
                                        <a className="nav-link active " style={{ color: "#11b6e4", fontWeight: "800" }} aria-current="page" href="/">HOME</a>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-2">
                                        <NavLink className="nav-link dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Industries
                                        </NavLink>
                                        <ul className="dropdown-menu" style={{ borderTop: "0.3125rem solid #11b6e4" }} >
                                            <li className="nav-item dropend ">
                                                <NavLink className="nav-link dropdown-toggle text-decoration-none" to="/enginaring" >
                                                    Enginaring  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                </NavLink>

                                                <ul className="dropdown-menu"  >
                                                    <li><NavLink className="dropdown-item text-decoration-none bg-black" to='/highvolumecomp'> HIGH VOLUME COMPONENTS </NavLink></li>

                                                    <li><NavLink className="dropdown-item text-decoration-none bg-black" to="/cncmachine"> CNC MACHINING </NavLink></li>

                                                    <li><NavLink className="dropdown-item text-decoration-none bg-black" to="/wireerosion"> WIRE EROSION </NavLink></li>

                                                    <li><NavLink className="dropdown-item text-decoration-none bg-black" to="/heavymachining"> HEAVY MACHINING  </NavLink></li>

                                                    <li><NavLink className="dropdown-item text-decoration-none bg-black" to="/castingsforgings"> CASTINGS & FORGINGS </NavLink></li>

                                                </ul>
                                            </li>

                                            <li className="nav-item dropend">
                                                <NavLink className="nav-link dropdown-toggle text-decoration-none"  >
                                                    METAL FABRICATION &nbsp; &nbsp; &nbsp;
                                                </NavLink>

                                                <ul className="dropdown-menu"  >
                                                    <li><NavLink className="dropdown-item text-decoration-none bg-black" to='/metalpressing'> METAL PRESSING &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  </NavLink></li>
                                                    <li><NavLink className="dropdown-item text-decoration-none bg-black" to='/sheetmetalwork'> SHEET METAL WORK &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </NavLink></li>
                                                </ul>
                                            </li>

                                            <li className='ms-1'><NavLink className="dropdown-item text-decoration-none bg-black " style={{fontSize:'14px'}} to="/rubber"> Rubber </NavLink></li>

                                            <li className="nav-item dropend w-100">
                                                <NavLink className="nav-link dropdown-toggle text-decoration-none"  >
                                                    plastics &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                </NavLink>

                                                <ul className="dropdown-menu"  >
                                                    <li><NavLink className="dropdown-item text-decoration-none bg-black" to='/plasticmachining'> PLASTIC MACHINING  &nbsp; &nbsp; &nbsp; &nbsp; </NavLink></li>

                                                    <li><NavLink className="dropdown-item text-decoration-none bg-black" to='/plasticmoduling'> PLASTIC MODULING &nbsp; &nbsp; &nbsp; &nbsp; </NavLink></li>

                                                    <li><NavLink className="dropdown-item text-decoration-none bg-black" to='/toolmaking'> TOOL MAKING &nbsp; &nbsp; &nbsp; &nbsp; </NavLink></li>

                                                    <li><NavLink className="dropdown-item text-decoration-none bg-black" to='/threed'> 3D PRINTING  &nbsp; &nbsp; &nbsp; &nbsp;</NavLink></li>

                                                </ul>
                                            </li>

                                            <li className='ms-1'><NavLink className="dropdown-item text-decoration-none bg-black" to="/electronics"> ELECTRONICS </NavLink></li>

                                            <li className='ms-1'><NavLink className="dropdown-item text-decoration-none bg-black" to="/construction"> CONSTRUCTION </NavLink></li>

                                            <li className='ms-1'><NavLink className="dropdown-item text-decoration-none bg-black" to="/manufacturing"> MANUFACTURING </NavLink></li>


                                        </ul>

                                    </li>

                                    <li className="nav-item dropdown pe-lg-2">
                                        <NavLink className="nav-link dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Websites
                                        </NavLink>
                                        <ul className="dropdown-menu" style={{ borderTop: "0.3125rem solid #11b6e4" }} >
                                            <li><NavLink className="dropdown-item bg-black" to="/latestwebiste"> Latest Websites </NavLink></li>
                                            <li className="nav-item dropend">
                                                <NavLink className="nav-link dropdown-toggle  text-decoration-none bg-black" to="/latestwebiste" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Website Design &nbsp;&nbsp;&nbsp;&nbsp;
                                                </NavLink>

                                                <ul className="dropdown-menu"  >
                                                    <li><NavLink className="dropdown-item text-decoration-none bg-black" to="/customwebsitedesign"> Custom Website Design </NavLink></li>
                                                    <li><NavLink className="dropdown-item text-decoration-none bg-black" to="/templatedesign"> Template Design </NavLink></li>
                                                </ul>
                                            </li>
                                            <li><NavLink className="dropdown-item text-decoration-none bg-black" to="/e-commerce"> E-Commerce </NavLink></li>
                                            <li><NavLink className="dropdown-item text-decoration-none bg-black" to="/websitemanagement"> Website Management </NavLink></li>
                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-2">
                                        <NavLink className="nav-link dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Video
                                        </NavLink>
                                        <ul className="dropdown-menu" style={{ borderTop: "0.3125rem solid #11b6e4" }} >
                                            <li><NavLink className="dropdown-item bg-black" to="/latestvideo"> Latest Videos </NavLink></li>
                                            <li><NavLink className="dropdown-item bg-black" to="/companyvideo"> Company Video </NavLink></li>
                                            <li><NavLink className="dropdown-item bg-black" to="/promotionalvideo"> Promotional Video </NavLink></li>
                                            <li><NavLink className="dropdown-item bg-black" to="/videowithphotography"> Video with Photography </NavLink></li>
                                            <li><NavLink className="dropdown-item bg-black" to="/socialmediavideo"> Social Media Video </NavLink></li>
                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-2">
                                        <NavLink className="nav-link dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Marketing
                                        </NavLink>
                                        <ul className="dropdown-menu" style={{ borderTop: "0.3125rem solid #11b6e4" }} >
                                            <li><NavLink className="dropdown-item bg-black" to="/seo"> SEO </NavLink></li>
                                            <li><NavLink className="dropdown-item bg-black" to="/socialmedia"> Social Media </NavLink></li>
                                            <li><NavLink className="dropdown-item bg-black" to="/ppc"> PPC </NavLink></li>
                                            <li><NavLink className="dropdown-item bg-black" to="/contentwriting"> Content Writing </NavLink></li>
                                            <li><NavLink className="dropdown-item bg-black" to="/creativedesign"> Creative Design </NavLink></li>
                                            <li><NavLink className="dropdown-item bg-black" to="/tracking"> Tracking & Analytics </NavLink></li>
                                            <li><NavLink className="dropdown-item bg-black" to="/outsourced"> Outsourced Management </NavLink></li>
                                            <li><NavLink className="dropdown-item bg-black" to="/brandevelopment"> Brand Development </NavLink></li>
                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-2">
                                        <NavLink className="nav-link dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Photography
                                        </NavLink>
                                        <ul className="dropdown-menu" style={{ borderTop: "0.3125rem solid #11b6e4" }} >
                                            <li><NavLink className="dropdown-item bg-black" to="/components"> COMPONENTS </NavLink></li>
                                            <li><NavLink className="dropdown-item bg-black" to="/onlocation"> ON LOACTION </NavLink></li>
                                        </ul>
                                    </li>

                                    <li className="nav-item dropdown pe-lg-2">
                                        <NavLink className="nav-link dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            About
                                        </NavLink>
                                        <ul className="dropdown-menu" style={{ borderTop: "0.3125rem solid #11b6e4" }} >
                                            <li><NavLink className="dropdown-item bg-black" to="/news"> News </NavLink></li>
                                            <li><NavLink className="dropdown-item bg-black" to="/team"> Meet The Team </NavLink></li>
                                            <li><NavLink className="dropdown-item bg-black" to="/carrers"> Careers </NavLink></li>
                                        </ul>
                                    </li>

                                    <li className="nav-item pe-lg-2">
                                        <NavLink className="nav-link " aria-current="page" to="/contact">Contact</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

                <div className="container-fluid p-0 d-sm-none d-block">
                    <nav className="navbar navbar-expand-lg  header-content" style={{ backgroundColor: "black", padding: ".655rem" }}>
                        <div className="container-fluid p-3 ">
                            <div className=''>
                                <NavLink to="/">
                                    <img className='' style={{ height: "4rem" }} src={require("../img/IndLOGO.png")} alt="icloudsoft_logo" />
                                </NavLink>
                            </div>
                            <button className="navbar-toggler bg-light" type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa-solid fa-bars"></i>
                            </button>
                            <div className="collapse navbar-collapse mt-2" style={{ backgroundColor: "#1c252a" }} id="navbarNavDropdown">
                                <ul className="navbar-nav FONT d-flex justify-content-lg-around align-items-lg-center w-100 p-3"  >
                                    <li className='nav-item pb-2' style={{ borderBottom: "0.0625rem solid rgba(17,182,228,.1)" }} >
                                        <NavLink className=" text-white text-decoration-none fs-6  " to="/" > <b className=''  > Home </b> </NavLink>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle text-white fs-6 pb-2 " style={{ borderBottom: "0.0625rem solid rgba(17,182,228,.1)" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Industries </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 bg-light  w-100 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px" }} id="submenu">
                                            <li className='nav-item'>
                                                <div className='container '>
                                                    <div className="row d-flex justify-content-around fs-6"  >
                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 text-white">
                                                            <NavLink className='text-decoration-none' to="/enginaring">
                                                                <div className='dropdown-item fs-6 text-dark'> <b className='' style={{ fontWeight: "800" }} > Enginaring </b> </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily ' to="/highvolumecomp" >
                                                                <div className='dropdown-item text-dark'> HIGH VOLUME COMPONENTS </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/cncmachine" >
                                                                <div className='dropdown-item text-dark'> CNC MACHINING </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/wireerosion" >
                                                                <div className='dropdown-item text-dark'> WIRE EROSION </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/heavymachining" >
                                                                <div className='dropdown-item text-dark'> HEAVY MACHINING </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/fastingsforgings" >
                                                                <div className='dropdown-item text-dark'> CASTINGS & FORGINGS  </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                        </div>

                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 text-white">
                                                            <NavLink className='text-decoration-none' to="/enginaring">
                                                                <div className='dropdown-item fs-6 text-dark'> <b className='' style={{ fontWeight: "800" }} > METAL FABRICATION </b> </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily ' to="/metalpressing" >
                                                                <div className='dropdown-item text-dark'> METAL PRESSING </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/sheetmetalwork" >
                                                                <div className='dropdown-item text-dark'> SHEET METAL WORK </div>
                                                                <div className="border" ></div>
                                                            </NavLink>


                                                        </div>

                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 ">
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/rubber" >
                                                                <div className='dropdown-item text-dark'> <b> RUBBER </b> </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                        </div>

                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 text-white">
                                                            <NavLink className='text-decoration-none'>
                                                                <div className='dropdown-item fs-6 text-dark'> <b className='' style={{ fontWeight: "800" }} > PLASTIC </b> </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily ' to="/plasticmachining" >
                                                                <div className='dropdown-item text-dark'> PLASTIC MACHINING  </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/plasticmoduling" >
                                                                <div className='dropdown-item text-dark'> PLASTIC MODULING </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/toolmaking" >
                                                                <div className='dropdown-item text-dark'> TOOL MAKING </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/threed" >
                                                                <div className='dropdown-item text-dark'> 3D PRINTING </div>
                                                                <div className="border" ></div>
                                                            </NavLink>


                                                        </div>

                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 ">
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/electronics" >
                                                                <div className='dropdown-item text-dark'> <b> ELECTRONICS </b> </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                        </div>

                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 ">
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/construction" >
                                                                <div className='dropdown-item text-dark'> <b> CONSTRUCTION </b> </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                        </div>

                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 ">
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/manufacturing" >
                                                                <div className='dropdown-item text-dark'> <b> MANUFACTURING </b> </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                        </div>

                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle text-white fs-6 pb-2 " style={{ borderBottom: "0.0625rem solid rgba(17,182,228,.1)" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Websites </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 bg-light  w-100 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className='container '>
                                                    <div className="row d-flex justify-content-around fs-6">
                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 ">
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/latestwebiste" >
                                                                <div className='dropdown-item text-dark'> <b> Latest Websites </b> </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                        </div>

                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 ">
                                                            <NavLink className='text-decoration-none' to="/latestwebiste">
                                                                <div className='dropdown-item text-dark fs-6 '><b> Website Design </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily ' to="/customwebsitedesign" >
                                                                <div className='dropdown-item text-dark'> Custom Website Design </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none SolutionDropDownFontFamily' to="/templatedesign" >
                                                                <div className='dropdown-item text-dark'> AMI Template Design </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                        </div>

                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 ">
                                                            <NavLink className='text-decoration-none' to="/e-commerce" >
                                                                <div className='dropdown-item text-dark fs-6'><b> E-Commerce </b> </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                        </div>
                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 ">
                                                            <NavLink className='text-decoration-none' to="/solutions/websitemanagement" >
                                                                <div className='dropdown-item text-dark fs-6'><b> Website Management </b> </div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle text-white fs-6 pb-2 " style={{ borderBottom: "0.0625rem solid rgba(17,182,228,.1)" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Video </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 bg-light  w-100 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className='container '>
                                                    <div className="row d-flex justify-content-around fs-6">
                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 ">
                                                            <NavLink className='text-decoration-none' to="/latestvideo">
                                                                <div className='dropdown-item text-dark fs-6 '><b> Latest Videos </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/companyvideo">
                                                                <div className='dropdown-item text-dark fs-6 '><b> Company Video </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/promotionalvideo">
                                                                <div className='dropdown-item text-dark fs-6 '><b> Promotional Video </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/videowithphotography">
                                                                <div className='dropdown-item text-dark fs-6 '><b> Video with Photography </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/socialmediavideo">
                                                                <div className='dropdown-item text-dark fs-6 '><b> Social Media Video </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle text-white fs-6 pb-2 " style={{ borderBottom: "0.0625rem solid rgba(17,182,228,.1)" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Marketing </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 bg-light  w-100 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className='container '>
                                                    <div className="row d-flex justify-content-around fs-6">
                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 ">
                                                            <NavLink className='text-decoration-none' to="/seo">
                                                                <div className='dropdown-item text-dark fs-6 '><b> SEO </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/socialmedia">
                                                                <div className='dropdown-item text-dark fs-6 '><b> Social Media </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/ppc">
                                                                <div className='dropdown-item text-dark fs-6 '><b> PPC </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/contentwriting">
                                                                <div className='dropdown-item text-dark fs-6 '><b> Content Writing </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/creativedesign">
                                                                <div className='dropdown-item text-dark fs-6 '><b> Creative Design </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/tracking">
                                                                <div className='dropdown-item text-dark fs-6 '><b> Tracking & Analytics </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/outsourced">
                                                                <div className='dropdown-item text-dark fs-6 '><b> Outsourced Management </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/brandevelopment">
                                                                <div className='dropdown-item text-dark fs-6 '><b> Brand Development </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle text-white fs-6 pb-2 " style={{ borderBottom: "0.0625rem solid rgba(17,182,228,.1)" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> Photography </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 bg-light  w-100 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className='container '>
                                                    <div className="row d-flex justify-content-around fs-6">
                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 ">
                                                            <NavLink className='text-decoration-none' to="/components">
                                                                <div className='dropdown-item text-dark fs-6 '><b> Components </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/onlocation">
                                                                <div className='dropdown-item text-dark fs-6 '><b> On Location </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item #submenu nav-link" data-bs-toggle="collapse" aria-current="true" >
                                        <div className="dropdown-toggle text-white fs-6 pb-2 " style={{ borderBottom: "0.0625rem solid rgba(17,182,228,.1)" }} id="mega-one" role="button" data-bs-toggle="dropdown" aria-expanded="true"><b> About </b></div>
                                        <ul className="dropdown-menu nav collapse p-2 bg-light  w-100 " aria-labelledby="mega-one" style={{ zIndex: "6", borderRadius: "0px 0px 0px 0px" }} id="submenu">
                                            <li className='nav-item '>
                                                <div className='container '>
                                                    <div className="row d-flex justify-content-around fs-6">
                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 ">
                                                            <NavLink className='text-decoration-none' to="/news">
                                                                <div className='dropdown-item text-dark fs-6 '><b> News </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/team">
                                                                <div className='dropdown-item text-dark fs-6 '><b> Meet The Team </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                            <NavLink className='text-decoration-none' to="/carrers">
                                                                <div className='dropdown-item text-dark fs-6 '><b> Careers </b></div>
                                                                <div className="border" ></div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item pb-2 mt-2' style={{ borderBottom: "0.0625rem solid rgba(17,182,228,.1)" }}>
                                        <NavLink className="text-white text-decoration-none fs-6  " to="/contact" > <b className=''> Contact </b> </NavLink>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </section>


        </>
    )
}

export default Header