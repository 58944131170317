import React from 'react'
import { NavLink } from 'react-router-dom'

const LatestVideo = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/latestVideoBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "50vh" }}>
                    <div className="container pt-5 pb-4">
                        <div className='row ps-lg-2'>
                            <h2 className='text-white' style={{ fontSize: "45px" }} >LATEST VIDEOS</h2>
                            <h2 className='' style={{ fontSize: "40px", color: "#11b6e4", fontWeight: "700", letterSpacing: "3px" }} > VIDEO PRODUCTION FOR UK MANUFACTURERS </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-lg-5" style={{ backgroundColor: "#000" }} >
                    <div className="container-lg pb-lg-5">
                        <div className="row mb-4 g-4 d-flex justify-content-center p-lg-2 FontFamilyMontSerret">
                            <div className="col-lg-4 col-md-6 ">
                                <div className="card">
                                    <img src={require("../img/LatestWebsite1.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>Wessington Cryogenics</h1>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 ">
                                <div className="card">
                                    <img src={require("../img/latestVideo1.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>Stevenage Sheet Metal</h1>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <img src={require("../img/latestVideo2.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>DePe Gear Company</h1>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 ">
                                <div className="card">
                                    <img src={require("../img/latestVideo3.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>MJ Sections</h1>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 ">
                                <div className="card">
                                    <img src={require("../img/latestVideo4.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>K Lacey</h1>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 ">
                                <div className="card">
                                    <img src={require("../img/latestVideo5.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>TJW Precision</h1>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='text-center mt-lg-5 pt-lg-5'>
                            <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                <button className=' ReadMoreButton' style={{ fontSize: "16px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > LOAD MORE </b> </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default LatestVideo