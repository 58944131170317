import React from 'react'
import { NavLink } from 'react-router-dom'

const News = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/NewsBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "50vh" }}>
                    <div className="container pt-5 pb-4">
                        <div className='row ps-lg-2'>
                            <h2 className='mt-lg-5' style={{ fontSize: "45px", color: "#11b6e4", fontWeight: "700", letterSpacing: "3px" }} > LATEST NEWS AT AMI </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-lg-5" style={{ backgroundColor: "#000" }} >
                    <div className="container-lg pb-lg-5">
                        <div className="row mb-4 g-4 d-flex justify-content-center p-lg-2 FontFamilyMontSerret">
                            <div className="col-lg-4 col-md-6 ">
                                <div className="card">
                                    <img src={require("../img/News1.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>READ MORE</h1>
                                    </div>
                                </div>
                                <div className='text-white text-center mt-2'>
                                    <p className='' style={{ fontSize: "18px" }} >Unveiling The Shearline Group’s Integrated Digital Presence</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 ">
                                <div className="card">
                                    <img src={require("../img/News2.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>READ MORE</h1>
                                    </div>
                                </div>
                                <div className='text-white text-center mt-2'>
                                    <p className='' style={{ fontSize: "18px" }} >Expertise in Aerospace Sector with Successful Project for MJ Sections</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="card">
                                    <img src={require("../img/News3.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>READ MORE</h1>
                                    </div>
                                </div>
                                <div className='text-white text-center mt-2'>
                                    <p className='' style={{ fontSize: "18px" }} >Behind the Screens Q3: Summer Success at AMI</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 ">
                                <div className="card">
                                    <img src={require("../img/News4.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>READ MORE</h1>
                                    </div>
                                </div>
                                <div className='text-white text-center mt-2'>
                                    <p className='' style={{ fontSize: "18px" }} >AMI’s HQ: More Than Just Desks!</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 ">
                                <div className="card">
                                    <img src={require("../img/News5.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>READ MORE</h1>
                                    </div>
                                </div>
                                <div className='text-white text-center mt-2'>
                                    <p className='' style={{ fontSize: "18px" }} >AMI Unlocked Q2: Behind the Screens…</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 ">
                                <div className="card">
                                    <img src={require("../img/News6.png")} className='' alt="" />
                                    <div className="intro">
                                        <h1>READ MORE</h1>
                                    </div>
                                </div>
                                <div className='text-white text-center mt-2'>
                                    <p className='' style={{ fontSize: "18px" }} >The Shearline Group’s 50th Anniversary Celebration…</p>
                                </div>
                            </div>
                        </div>

                        <div className='text-center mt-lg-4 pt-lg-4'>
                            <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                <button className=' ReadMoreButton' style={{ fontSize: "16px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > LOAD MORE </b> </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default News