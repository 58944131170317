import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Header from './Header'
import Home from './Home'
import Icons from './Icons'
import Silder from './Silder'
import Footer from './Footer'
import Error from './Error'
import Enginaring from './Enginaring'
import HighVolumeComp from './HighVolumeComp'
// import ImgHover from './ImgHover'
import LatestWebiste from './LatestWebiste'
import CncMachine from './CncMachine'
import WireErosion from './WireErosion'
import HeavyMachining from './HeavyMachining'
import CastingsForgings from './CastingsForgings'
import MetalPressing from './MetalPressing'
import SheeetMetalWork from './SheeetMetalWork'
import Rubber from './Rubber'
import PlasticMachining from './PlasticMachining'
import PlasticModuling from './PlasticModuling'
import ToolMaking from './ToolMaking'
import ThreeD from './ThreeD'
import Electronics from './Electronics'
import Construction from './Construction'
import Manufacturing from './Manufacturing'
import CustomWebsiteDesign from './CustomWebsiteDesign'
import TemplateDesign from './TemplateDesign'
import Ecommerce from './Ecommerce'
import WebsiteManagement from './WebsiteManagement'
import LatestVideo from './LatestVideo'
import CompanyVideo from './CompanyVideo'
import PromotionalVideo from './PromotionalVideo'
import VideoWithPhotography from './VideoWithPhotography'
import SocialMediaVideo from './SocialMediaVideo'
import Seo from './Seo'
import SocialMedia from './SocialMedia'
import Ppc from './Ppc'
import ContentWriting from './ContentWriting'
import CreativeDesign from './CreativeDesign'
import Tracking from './Tracking'
import Outsourced from './Outsourced'
import BrandDevelopment from './BrandDevelopment'
import Components from './Components'
import OnLocation from './OnLocation'
import Carrers from './Carrers'
import Contact from './Contact'
import News from './News'
import MeetTeam from './MeetTeam'
import TearmUse from './TearmUse'
import PrivacyPolicy from './PrivacyPolicy'
import CookiePolicy from './CookiePolicy'
import AcceptableUsePolicy from './AcceptableUsePolicy'

const Routing = () => {
    return (
        <>

            <React.Fragment>
                <header className='sticky-top' >
                    <Header />
                    <Icons/>
                </header>

                <main>
                    <Routes>
                        <Route to path="/" element={<Home />} />
                        <Route to path="/icons" element={<Icons />} />
                        <Route to path="/silder" element={<Silder />} />
                        <Route to path="/enginaring" element={<Enginaring />} />
                        <Route to path="/highvolumecomp" element={<HighVolumeComp />} />
                        {/* <Route to path="/imghover" element={<ImgHover />} /> */}
                        <Route to path="/latestwebiste" element={<LatestWebiste />} />
                        <Route to path="/cncmachine" element={<CncMachine />} />
                        <Route to path="/wireerosion" element={<WireErosion />} />
                        <Route to path="/heavymachining" element={<HeavyMachining />} />
                        <Route to path="/castingsforgings" element={<CastingsForgings />} />

                        <Route to path="/metalpressing" element={<MetalPressing />} />
                        <Route to path="/sheetmetalwork" element={<SheeetMetalWork />} />
                        <Route to path="/rubber" element={<Rubber />} />
                        <Route to path="/plasticmachining" element={<PlasticMachining />} />
                        <Route to path="/plasticmoduling" element={<PlasticModuling />} />
                        <Route to path="/toolmaking" element={<ToolMaking />} />
                        <Route to path="/threed" element={<ThreeD />} />
                        <Route to path="/electronics" element={<Electronics />} />
                        <Route to path="/construction" element={<Construction />} />
                        <Route to path="/manufacturing" element={<Manufacturing />} />
                        <Route to path="/latestwebiste" element={<LatestWebiste />} />
                        <Route to path="/customwebsitedesign" element={<CustomWebsiteDesign />} />
                        <Route to path="/templatedesign" element={<TemplateDesign />} />
                        <Route to path="/e-commerce" element={<Ecommerce />} />
                        <Route to path="/websitemanagement" element={<WebsiteManagement />} />
                        <Route to path="/latestvideo" element={<LatestVideo />} />
                        <Route to path="/companyvideo" element={<CompanyVideo />} />
                        <Route to path="/promotionalvideo" element={<PromotionalVideo />} />
                        <Route to path="/videowithphotography" element={<VideoWithPhotography />} />
                        <Route to path="/socialmediavideo" element={<SocialMediaVideo />} />
                        <Route to path="/seo" element={<Seo />} />
                        <Route to path="/socialmedia" element={<SocialMedia />} />
                        <Route to path="/ppc" element={<Ppc />} />
                        <Route to path="/contentwriting" element={<ContentWriting />} />
                        <Route to path="/creativedesign" element={<CreativeDesign />} />
                        <Route to path="/tracking" element={<Tracking />} />
                        <Route to path="/outsourced" element={<Outsourced />} />
                        <Route to path="/brandevelopment" element={<BrandDevelopment />} />
                        <Route to path="/components" element={<Components />} />
                        <Route to path="/onlocation" element={<OnLocation />} />

                        <Route to path="/news" element={<News />} />
                        <Route to path="/team" element={<MeetTeam />} />


                        <Route to path="/carrers" element={<Carrers />} />
                        <Route to path="/contact" element={<Contact />} />

                        <Route to path="/tearmuse" element={<TearmUse />} />
                        <Route to path="/privacypolicy" element={<PrivacyPolicy />} />
                        <Route to path="/cookiepolicy" element={<CookiePolicy />} />
                        <Route to path="/acceptableusepolicy" element={<AcceptableUsePolicy />} />



                        {/* <Route to path="/reactbootstrap" element={<ReactBootstrap />} /> */}

                        
                        {/* <Route to path="/loader" element={<Loader />} /> */}

                        <Route to path="*" element={<Error />} />
                    </Routes>
                </main>

                <footer>
                    <Footer/>
                </footer>

            </React.Fragment>

        </>
    )
}

export default Routing