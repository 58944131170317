import React from 'react'
import "./Silder.css"

const Silder = () => {
    return (
        <>

            <div className="container-fluid shadow-lg d-lg-block d-none FONT" style={{ backgroundColor: "#fff" }} >
                <div className="testimonials ">
                    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner ">
                            <div class="carousel-item active">
                                <div className="single-item">
                                    <div className="row  w-100 d-flex justify-content-center" >
                                        <div className="col-lg-2 ">
                                            <div className="profile">
                                                <div className="img-area">
                                                    <img className=' ' src={require("../img/silder1.png")} alt="emp1" title='emp-1' />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 ">
                                            <div className="profile">
                                                <div className="img-area">
                                                    <img className=' ' src={require("../img/silder2.png")} alt="emp1" title='emp-1' />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 ">
                                            <div className="profile">
                                                <div className="img-area">
                                                    <img className=' ' src={require("../img/silder3.png")} alt="emp1" title='emp-1' />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 ">
                                            <div className="profile">
                                                <div className="img-area">
                                                    <img className=' ' src={require("../img/silder4.png")} alt="emp1" title='emp-1' />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 ">
                                            <div className="profile">
                                                <div className="img-area">
                                                    <img className=' ' src={require("../img/silder5.png")} alt="emp1" title='emp-1' />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="carousel-item">
                                <div className="single-item">
                                    <div className="row  w-100 d-flex justify-content-center" >
                                        <div className="col-lg-2 ">
                                            <div className="profile">
                                                <div className="img-area">
                                                    <img className=' ' src={require("../img/silder1.png")} alt="emp1" title='emp-1' />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 ">
                                            <div className="profile">
                                                <div className="img-area">
                                                    <img className=' ' src={require("../img/silder2.png")} alt="emp1" title='emp-1' />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 ">
                                            <div className="profile">
                                                <div className="img-area">
                                                    <img className=' ' src={require("../img/silder3.png")} alt="emp1" title='emp-1' />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 ">
                                            <div className="profile">
                                                <div className="img-area">
                                                    <img className=' ' src={require("../img/silder4.png")} alt="emp1" title='emp-1' />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 ">
                                            <div className="profile">
                                                <div className="img-area">
                                                    <img className=' ' src={require("../img/silder5.png")} alt="emp1" title='emp-1' />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="conatiner-fluid shadow-lg  d-block d-md-none  FONT" style={{ backgroundColor: "#fff" }} >
                <div class="">
                    <div className="testimonials">
                        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div className="single-item">
                                        <div className="row  w-100 d-flex justify-content-center" >
                                            <div className="col-lg-2">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/silder1.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row  w-100 d-flex justify-content-center" >
                                            <div className="col-lg-2">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/silder2.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row  w-100 d-flex justify-content-center" >
                                            <div className="col-lg-2">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/silder3.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row  w-100 d-flex justify-content-center" >
                                            <div className="col-lg-2">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/silder4.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="carousel-item">
                                    <div className="single-item">
                                        <div className="row  w-100 d-flex justify-content-center" >
                                            <div className="col-lg-2">
                                                <div className="profile">
                                                    <div className="img-area">
                                                        <img className=' ' src={require("../img/silder5.png")} alt="emp1" title='emp-1' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div >
            </div>


        </>
    )
}

export default Silder