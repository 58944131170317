import React from 'react'
import { NavLink } from 'react-router-dom'
import Silder from './Silder'

const TemplateDesign = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/CustomWebsiteBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "50vh" }}>
                    <div className="container pt-5 pb-4">
                        <div className='row ps-lg-2'>
                            <h2 className='text-white' style={{ fontSize: "45px" }} >AMI TEMPLATE DESIGN ©️</h2>
                            <h2 className='' style={{ fontSize: "40px", color: "#11b6e4", fontWeight: "700", letterSpacing: "3px" }} > FOR UK MANUFACTURERS </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-0" style={{ backgroundColor: "black" }} >
                    <div className="container-lg p-lg-3">
                        <div className="row p-lg-2 pt-4 mt-lg-5 d-flex justify-content-lg-center align-items-center FontFamilyMontSerret">
                            <div className="col-lg-8 col-md-8">
                                <div className=''>
                                    <h2 className='pb-2' style={{ fontSize: "2.3rem", color: "#fff", fontWeight: "700" }} >CUSTOM BUILT TEMPLATES</h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.15rem", color: "#11b6e4", fontWeight: "700", letterSpacing: "1px" }} > Bespoke built websites for SMEs, high performance and good value  </h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > For over 10 years our in-house team of website designers, developers and project managers has built dedicated, unique websites for manufacturers UK-wide. Working alongside both SMEs and multinational companies has enabled us to identify the best online solutions for manufacturers and engineers alike, who are looking to increase enquiries quickly. </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }}> Our custom-built templates are created by our in-house developers and tailored to satisfy customer requirements. With a selection of custom-built templates to choose from, you’ll also have the option to customise the colour scheme, text, images and page structure if desired. This is the perfect opportunity for a company who’s looking to generate more online enquiries, but doesn’t have a specific design brief in mind </p>

                                </div>

                                <div className='row  g-4 pb-4 mt-4 pt-4 '>
                                    <div className=''>
                                        <NavLink className='' to="/templatedesign">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > LATEST TEMPLATES </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='row d-flex justify-content-around align-items-center FontFamilyMontSerret'>
                                    <div className='p-3' style={{ width: "18.75rem", color: "#fff", fontWeight: "700", border: "1.25rem solid #11b6e4", borderImage: "linear-gradient(45deg,#11b6e4,#11b6e4 3.75rem,transparent 3.75rem,transparent 5rem,#11b6e4 3.75rem) 12" }} >
                                        <h2 className='' style={{ fontSize: "1.02rem", lineHeight: "25px" }} >Good quality and professional service on both customer service, website design and development. It was really beneficial having a team with experience & knowledge of the manufacturing industry. </h2>
                                    </div>
                                    <div className='mt-3 text-center ps-lg-5 ms-lg-5' style={{ lineHeight: "1" }} >
                                        <h2 className='ps-lg-5' style={{ fontSize: "1rem", color: "#fff", fontWeight: "700", lineHeight: ".8" }} >Victoria Forst</h2>
                                        <h2 className='ps-lg-5' style={{ fontSize: ".725rem", color: "#fff", fontWeight: "700", lineHeight: "1.3" }} >Hitec Sheet Meta</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container-fluid d-sm-none d-block " style={{ backgroundColor: "black" }} >
                    <div className="container p-lg-3 pb-lg-5">
                        <div className="row p-2 d-flex justify-content-center align-items-center">
                            <div className="col-lg-7 pt-lg-5 ">
                                <div className='pt-lg-5 mt-lg-5'>
                                    <h2 className='pb-2' style={{ fontSize: "2.675rem", color: "#fff" }} > INDIA'S LEADING INDUSTRIAL MARKETING MANAGEMENT AGENCY </h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700" }} >Promoting INDIA engineering, manufacturing & industrial companies</h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9475rem", color: "#fff", lineHeight: "1.375" }} >ICLOUD – Marketing for Manufacturing is the INDIA’s leading industrial marketing management agency specialising in helping manufacturers and engineers gain more enquiries online. Over the years the ICLOUD team has acquired a wealth of knowledge working with hundreds of industrial companies. We understand the most effective ways to market companies in the industrial sector – from affordable website development to promotional video and search marketing that delivers new business enquiries – and have an extensive track record and a growing base of very happy customers.</p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9575rem", color: "#fff", lineHeight: "1.375" }}>We are trusted by INDIA engineers, manufacturers and industrial businesses of all sizes and budgets, helping them to successfully promote their businesses online. We offer a comprehensive and affordable outsourced solution, with outstanding customer service that delivers on its promises. ICLOUD – Marketing for Manufacturing, your one-stop shop for all your industrial marketing needs.</p>
                                </div>

                                <div className='row g-4 pb-4 mt-3 pt-3'>
                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > INSIDE ICLOUD </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > TESTIMONIALS </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > LATEST WEBSITES </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block" style={{ backgroundColor: "black" }} >
                    <div className=" p-lg-3 ">
                        <div className="row mb-5 d-flex justify-content-between align-items-center FontFamilyMontSerret">
                            <div className="col-lg-6">
                                <div className=' mt-lg-5 ps-lg-5 ms-lg-5'>
                                    <h2 className='pb-2 FontFamilyMontSerret' style={{ fontSize: "2.275rem", color: "#fff", fontWeight: "800", letterSpacing: "2px" }} > NO IDEA? NO WORRIES! </h2>
                                    <h3 className='mt-2 pb-2 FontFamilyMontSerret' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700", letterSpacing: "1px" }} > Quality website designs built solely for manufacturers </h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > AMI has designed, built and launched more than 250 websites for UK manufacturers over the past decade. Our experience means we understand exactly how these businesses need to market themselves online to generate new enquiries. </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > With this being said, we’ve put together 3 carefully thought-out designs for you to choose from. Each option embodies the key fundamentals of any successful website, whilst remaining credible and approachable. Everything is totally customisable with your company branding, images and content, and we’re always happy to work closely alongside you to bring your ideas to life. </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > Please see the designs below or get in touch for more information. </p>
                                    <div className='pt-lg-4'>
                                        <NavLink className='d-flex justify-content-start align-items-center text-decoration-none' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3 ' style={{ letterSpacing: "1px" }} > WEBSITES </b> </button>
                                        </NavLink>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className=''>
                                    <img className='w-100' style={{ height: "25rem" }} src={require("../img/CustomWebsite1.png")} alt="emp1" title='emp-1' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid ">
                    <div className="row flex">
                        <div className="col-lg-3 col-md-6 p-0">
                            <div className="card">
                                <img src={require("../img/templateDesign1.png")} className='' alt="" />
                                <div className="intro">
                                    <h1>Design 1</h1>
                                    <h6>SEE OUR DESIGNS </h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <div className="card">
                                <img src={require("../img/templateDesign2.png")} className='' alt="" />
                                <div className="intro">
                                    <h1>Design 2</h1>
                                    <h6>SEE OUR DESIGNS</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <div className="card">
                                <img src={require("../img/templateDesign3.png")} className='' alt="" />
                                <div className="intro">
                                    <h1>Design 3</h1>
                                    <h6>SEE OUR DESIGNS</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <div className="card">
                                <img src={require("../img/templateDesign4.png")} className='' alt="" />
                                <div className="intro">
                                    <h1>Design 4</h1>
                                    <h6>SEE OUR DESIGNS</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <div className="card">
                                <img src={require("../img/templateDesign5.png")} className='' alt="" />
                                <div className="intro">
                                    <h1>Design 5</h1>
                                    <h6>SEE OUR DESIGNS</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <div className="card">
                                <img src={require("../img/templateDesign6.png")} className='' alt="" />
                                <div className="intro">
                                    <h1>Design 6</h1>
                                    <h6>SEE OUR DESIGNS</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <div className="card">
                                <img src={require("../img/templateDesign7.png")} className='' alt="" />
                                <div className="intro">
                                    <h1>Design 7</h1>
                                    <h6>SEE OUR DESIGNS</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <div className="card">
                                <img src={require("../img/templateDesign8.png")} className='' alt="" />
                                <div className="intro">
                                    <h1>Contact Us</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block" style={{ borderTop: "0.375rem solid #11b6e4" }} >
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-lg-8">
                            <div className="p-lg-5   ">
                                <h2 className='ps-lg-5 ms-lg-4 mb-4' style={{ color: "#11b6e4", fontSize: "2.8rem", fontWeight: "800" }} >SUPPORTING INDIA ENGINEERING</h2>
                                <p className='ps-lg-5 ms-lg-4 FONT' style={{ fontSize: "1.5rem", fontWeight: "600" }} >Dedicated to maximising exposure for UK Heavy & Large Part Machinists</p>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0">
                            <img className='w-100 ' style={{ height: "23rem" }} src={require("../img/last1.png")} alt="emp1" title='emp-1' />
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default TemplateDesign