import React from 'react'
import "./Home.css";
import { NavLink } from 'react-router-dom'
import Silder from './Silder'

const Home = () => {

  return (

    <>



      <section>
        <div className="container-fluid d-none d-sm-block p-0 m-0" >
          <div className='VIDEOheight position-relative'>
            <video autoPlay loop muted className='w-100' style={{ objectFit: "fill", height: "100%" }}  >
              <source src={require("../img/indVIDEO1.webm")} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className='text-white position-absolute FontFAMIlyMontSerret' style={{ top: "25%", left: "8%" }} >
              <div className=' HEADING '> Marketing for  </div>
              <div className=' HEADING ps-lg-5'>  Manufacturing™ </div>
            </div>
          </div>
        </div>

        <div className="container-fluid p-0 m-0 d-sm-none d-block" style={{ height: "300px" }} >
          <div className=' position-relative'>
            <video autoPlay loop muted className='w-100' style={{ objectFit: "fill", height: "300px" }}  >
              <source src={require("../img/indVIDEO1.webm")} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className='text-white position-absolute FontFAMIlyMontSerret' style={{ top: "25%", left: "8%" }} >
              <div className=' HEADING '> Marketing for  </div>
              <div className=' HEADING ps-lg-5'>  Manufacturing™ </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid d-none d-sm-block" style={{ backgroundColor: "black" }} >
          <div className="container p-lg-3 pb-lg-5">
            <div className="row p-2 d-flex justify-content-center align-items-center">
              <div className="col-lg-7 pt-lg-5 ">
                <div className='pt-lg-5 mt-lg-5'>
                  <h2 className='pb-2' style={{ fontSize: "2.575rem", color: "#fff" }} > INDIA'S LEADING INDUSTRIAL MARKETING MANAGEMENT AGENCY , PUNE </h2>
                  <h3 className='mt-2 pb-2' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700" }} >Promoting INDIA engineering, manufacturing & industrial companies</h3>
                  <p className='pb-2 mt-3' style={{ fontSize: ".9475rem", color: "#fff", lineHeight: "1.375" }} >AMI – Marketing for Manufacturing is the INDIA’s leading industrial marketing management agency specialising in helping manufacturers and engineers gain more enquiries online. Over the years the AMI team has acquired a wealth of knowledge working with hundreds of industrial companies. We understand the most effective ways to market companies in the industrial sector – from affordable website development to promotional video and search marketing that delivers new business enquiries – and have an extensive track record and a growing base of very happy customers.</p>
                  <p className='pb-2 mt-3' style={{ fontSize: ".9575rem", color: "#fff", lineHeight: "1.375" }}>We are trusted by INDIA engineers, manufacturers and industrial businesses of all sizes and budgets, helping them to successfully promote their businesses online. We offer a comprehensive and affordable outsourced solution, with outstanding customer service that delivers on its promises. AMI – Marketing for Manufacturing, your one-stop shop for all your industrial marketing needs.</p>
                </div>

                <div className='row g-4 pb-4 mt-3 pt-3'>
                  <div className='col-lg-4 col-md-6'>
                    <NavLink className='' to="/contact">
                      <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > CONTACT US </b> </button>
                    </NavLink>
                  </div>

                  <div className='col-lg-4 col-md-6'>
                    <NavLink className='' to="/latestwebiste">
                      <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > LATEST WEBSITES </b> </button>
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                <div>
                  <img className='w-100 ' src={require("../img/col1.png")} alt="emp1" title='emp-1' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid d-sm-none d-block " style={{ backgroundColor: "black" }} >
          <div className="container p-lg-3 pb-lg-5">
            <div className="row p-2 d-flex justify-content-center align-items-center">
              <div className="col-lg-7 pt-lg-5 ">
                <div className='pt-lg-5 mt-lg-5'>
                  <h2 className='pb-2' style={{ fontSize: "2.675rem", color: "#fff" }} > INDIA'S LEADING INDUSTRIAL MARKETING MANAGEMENT AGENCY </h2>
                  <h3 className='mt-2 pb-2' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700" }} >Promoting INDIA engineering, manufacturing & industrial companies</h3>
                  <p className='pb-2 mt-3' style={{ fontSize: ".9475rem", color: "#fff", lineHeight: "1.375" }} >AMI – Marketing for Manufacturing is the INDIA’s leading industrial marketing management agency specialising in helping manufacturers and engineers gain more enquiries online. Over the years the AMI team has acquired a wealth of knowledge working with hundreds of industrial companies. We understand the most effective ways to market companies in the industrial sector – from affordable website development to promotional video and search marketing that delivers new business enquiries – and have an extensive track record and a growing base of very happy customers.</p>
                  <p className='pb-2 mt-3' style={{ fontSize: ".9575rem", color: "#fff", lineHeight: "1.375" }}>We are trusted by INDIA engineers, manufacturers and industrial businesses of all sizes and budgets, helping them to successfully promote their businesses online. We offer a comprehensive and affordable outsourced solution, with outstanding customer service that delivers on its promises. AMI – Marketing for Manufacturing, your one-stop shop for all your industrial marketing needs.</p>
                </div>

                <div className='row g-4 pb-4 mt-3 pt-3'>
                  <div className='col-lg-4 col-md-6'>
                    <NavLink className='' to="https://www.AMIsoftware.co.in/">
                      <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > INSIDE AMI </b> </button>
                    </NavLink>
                  </div>

                  <div className='col-lg-4 col-md-6'>
                    <NavLink className='' to="https://www.AMIsoftware.co.in/">
                      <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > TESTIMONIALS </b> </button>
                    </NavLink>
                  </div>

                  <div className='col-lg-4 col-md-6'>
                    <NavLink className='' to="https://www.AMIsoftware.co.in/">
                      <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > LATEST WEBSITES </b> </button>
                    </NavLink>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-5">
                <div>
                  <img className='w-100 ' src={require("../img/col1.png")} alt="emp1" title='emp-1' />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid p-0" style={{ backgroundColor: "black" }} >
          <div className="container p-3 pt-lg-5 pb-lg-5">
            <div className="row g-4 p-2 pt-lg-5 ">
              <div className='FontFAMIlyMontSerret pb-lg-4 mb-2'>
                <h2 className='' style={{ fontSize: "2.375rem", lineHeight: "2.25rem", color: "#fff", fontWeight: "800", letterSpacing: "1px" }} >MANUFACTURING MARKETING IS OUR FOCUS</h2>
                <h3 className='mt-2' style={{ fontSize: "1.1rem", lineHeight: "2.25rem", color: "#11b6e4", fontWeight: "800", letterSpacing: "1px" }} >Marketing Specialists for the following sectors</h3>
              </div>

              <div className="col-lg-4">
                <div className=''>
                  <i class="fa-sharp fa-solid fa-angle-right text-white"></i>
                  <span className='ps-2' style={{ fontSize: "1.0625rem", color: "#11b6e4", letterSpacing: "1px" }}>PRECISION ENGINEERING</span>
                </div>
                <div className='mt-1'>
                  <span className='ps-3' style={{ fontSize: "1rem", color: "#fff" }} >Multi-Axis, CNC Turning, Milling, Grinding</span>
                  <i class="ms-2 fa-sharp fa-solid fa-angle-right text-white"></i>
                </div>
              </div>

              <div className="col-lg-4">
                <div className=''>
                  <i class="fa-sharp fa-solid fa-angle-right text-white"></i>
                  <span className='ps-2' style={{ fontSize: "1.0625rem", color: "#11b6e4", letterSpacing: "1px" }}>FORGINGS & CASTINGS</span>
                </div>
                <div className='mt-1'>
                  <span className='ps-3' style={{ fontSize: "1rem", color: "#fff" }} >Hot Stampings, Die Castings, Patternmaking</span>
                  <i class="ms-2 fa-sharp fa-solid fa-angle-right text-white"></i>
                </div>
              </div>

              <div className="col-lg-4">
                <div className=''>
                  <i class="fa-sharp fa-solid fa-angle-right text-white"></i>
                  <span className='ps-2' style={{ fontSize: "1.0625rem", color: "#11b6e4", letterSpacing: "1px" }}>INJECTION MOULDING</span>
                </div>
                <div className='mt-1'>
                  <span className='ps-3' style={{ fontSize: "1rem", color: "#fff" }} >Injection & Compression Moulding</span>
                  <i class="ms-2 fa-sharp fa-solid fa-angle-right text-white"></i>
                </div>
              </div>

            </div>

            <div className="row g-4 p-2 pt-lg-5 ">
              <div className="col-lg-4">
                <div className=''>
                  <i class="fa-sharp fa-solid fa-angle-right text-white"></i>
                  <span className='ps-2' style={{ fontSize: "1.0625rem", color: "#11b6e4", letterSpacing: "1px" }}>HIGH VOLUME COMPONENTS</span>
                </div>
                <div className='mt-1'>
                  <span className='ps-3' style={{ fontSize: "1rem", color: "#fff" }} >Turned & Milled Parts, Miniature Machining</span>
                  <i class="ms-2 fa-sharp fa-solid fa-angle-right text-white"></i>
                </div>
              </div>

              <div className="col-lg-4">
                <div className=''>
                  <i class="fa-sharp fa-solid fa-angle-right text-white"></i>
                  <span className='ps-2' style={{ fontSize: "1.0625rem", color: "#11b6e4", letterSpacing: "1px" }}>METAL PRESSING</span>
                </div>
                <div className='mt-1'>
                  <span className='ps-3' style={{ fontSize: "1rem", color: "#fff" }} >Deep Drawn Pressings, Metal Presswork</span>
                  <i class="ms-2 fa-sharp fa-solid fa-angle-right text-white"></i>
                </div>
              </div>

              <div className="col-lg-4">
                <div className=''>
                  <i class="fa-sharp fa-solid fa-angle-right text-white"></i>
                  <span className='ps-2' style={{ fontSize: "1.0625rem", color: "#11b6e4", letterSpacing: "1px" }}>ELECTRONICS</span>
                </div>
                <div className='mt-1'>
                  <span className='ps-3' style={{ fontSize: "1rem", color: "#fff" }} >Cable Assembly, CEM, PCB</span>
                  <i class="ms-2 fa-sharp fa-solid fa-angle-right text-white"></i>
                </div>
              </div>

            </div>

            <div className="row g-4 p-2 pt-lg-5 ">
              <div className="col-lg-4">
                <div className=''>
                  <i class="fa-sharp fa-solid fa-angle-right text-white"></i>
                  <span className='ps-2' style={{ fontSize: "1.0625rem", color: "#11b6e4", letterSpacing: "1px" }}>CNC MACHINING</span>
                </div>
                <div className='mt-1'>
                  <span className='ps-3' style={{ fontSize: "1rem", color: "#fff" }} >3, 4, 5 Axis Machining, Fixed & Sliding Head</span>
                  <i class="ms-2 fa-sharp fa-solid fa-angle-right text-white"></i>
                </div>
              </div>

              <div className="col-lg-4">
                <div className=''>
                  <i class="fa-sharp fa-solid fa-angle-right text-white"></i>
                  <span className='ps-2' style={{ fontSize: "1.0625rem", color: "#11b6e4", letterSpacing: "1px" }}>SHEET METAL WORK</span>
                </div>
                <div className='mt-1'>
                  <span className='ps-3' style={{ fontSize: "1rem", color: "#fff" }} >Laser Cutting, CNC Punching, Forming</span>
                  <i class="ms-2 fa-sharp fa-solid fa-angle-right text-white"></i>
                </div>
              </div>

              <div className="col-lg-4">
                <div className=''>
                  <i class="fa-sharp fa-solid fa-angle-right text-white"></i>
                  <span className='ps-2' style={{ fontSize: "1.0625rem", color: "#11b6e4", letterSpacing: "1px" }}>TOOLMAKING</span>
                </div>
                <div className='mt-1'>
                  <span className='ps-3' style={{ fontSize: "1rem", color: "#fff" }} >Mould Toolmaking, Vacuum Form Toolmaking</span>
                  <i class="ms-2 fa-sharp fa-solid fa-angle-right text-white"></i>
                </div>
              </div>

            </div>

            <div className="row g-4 p-2 pt-lg-5 ">
              <div className="col-lg-4">
                <div className=''>
                  <i class="fa-sharp fa-solid fa-angle-right text-white"></i>
                  <span className='ps-2' style={{ fontSize: "1.0625rem", color: "#11b6e4", letterSpacing: "1px" }}>WIRE EROSION</span>
                </div>
                <div className='mt-1'>
                  <span className='ps-3' style={{ fontSize: "1rem", color: "#fff" }} >EDM Machining, Wire & Spark Erosion</span>
                  <i class="ms-2 fa-sharp fa-solid fa-angle-right text-white"></i>
                </div>
              </div>

              <div className="col-lg-4">
                <div className=''>
                  <i class="fa-sharp fa-solid fa-angle-right text-white"></i>
                  <span className='ps-2' style={{ fontSize: "1.0625rem", color: "#11b6e4", letterSpacing: "1px" }}>RUBBER</span>
                </div>
                <div className='mt-1'>
                  <span className='ps-3' style={{ fontSize: "1rem", color: "#fff" }} >Moulding, Extrusions, Engineering</span>
                  <i class="ms-2 fa-sharp fa-solid fa-angle-right text-white"></i>
                </div>
              </div>

              <div className="col-lg-4">
                <div className=''>
                  <i class="fa-sharp fa-solid fa-angle-right text-white"></i>
                  <span className='ps-2' style={{ fontSize: "1.0625rem", color: "#11b6e4", letterSpacing: "1px" }}>3D PRINTING</span>
                </div>
                <div className='mt-1'>
                  <span className='ps-3' style={{ fontSize: "1.055rem", color: "#fff" }} >Prototyping, Low Volume, Additive Manufacturing</span>
                  <i class="ms-2 fa-sharp fa-solid fa-angle-right text-white"></i>
                </div>
              </div>

            </div>

            <div className="row g-4 p-2 pt-lg-5 ">
              <div className="col-lg-4">
                <div className=''>
                  <i class="fa-sharp fa-solid fa-angle-right text-white"></i>
                  <span className='ps-2' style={{ fontSize: "1.0625rem", color: "#11b6e4", letterSpacing: "1px" }}>HEAVY MACHINING</span>
                </div>
                <div className='mt-1'>
                  <span className='ps-3' style={{ fontSize: "1rem", color: "#fff" }} >Large Part Machining, Honing, Drilling</span>
                  <i class="ms-2 fa-sharp fa-solid fa-angle-right text-white"></i>
                </div>
              </div>

              <div className="col-lg-4">
                <div className=''>
                  <i class="fa-sharp fa-solid fa-angle-right text-white"></i>
                  <span className='ps-2' style={{ fontSize: "1.0625rem", color: "#11b6e4", letterSpacing: "1px" }}>PLASTIC MACHINING</span>
                </div>
                <div className='mt-1'>
                  <span className='ps-3' style={{ fontSize: "1rem", color: "#fff" }} >CNC Turning, Milling, Routing, Cutting</span>
                  <i class="ms-2 fa-sharp fa-solid fa-angle-right text-white"></i>
                </div>
              </div>

              <div className="col-lg-4">
                <div className=''>
                  <i class="fa-sharp fa-solid fa-angle-right text-white"></i>
                  <span className='ps-2' style={{ fontSize: "1.0625rem", color: "#11b6e4", letterSpacing: "1px" }}>CONSTRUCTION</span>
                </div>
                <div className='mt-1'>
                  <span className='ps-3' style={{ fontSize: "1rem", color: "#fff" }} >Groundworks, Residential, Commercial</span>
                  <i class="ms-2 fa-sharp fa-solid fa-angle-right text-white"></i>
                </div>
              </div>

            </div>

          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "black" }}>
          <div className="container pt-5 p-3">
            <div className="row pt-lg-5 mt-lg-5 p-lg-3">
              <div>
                <h2 className='' style={{ fontSize: "2.375rem", lineHeight: "2.25rem", color: "#fff", fontWeight: "800", letterSpacing: "1px" }} >HOW WE HELP INDIA MANUFACTURERS</h2>
                <h3 className='mt-2' style={{ fontSize: "1.1rem", lineHeight: "2.25rem", color: "#11b6e4", fontWeight: "800", letterSpacing: "1px" }} >Offering the complete range of digital industrial marketing services</h3>
                <p className='pb-2 mt-lg-3' style={{ fontSize: ".9975rem", color: "#fff" }}>AMI - Marketing for Manufacturing offers a complete marketing solution for engineering and manufacturing companies looking to increase new business enquiries online. Our highly experienced team offers affordable industrial marketing management solutions for companies of all sizes and budgets – a complete solution that includes: website design, SEO, video production, photography, social media management, Google Ads / PPC and more. </p>
                <p className='pb-2 mt-3' style={{ fontSize: ".9975rem", color: "#fff" }}>As the INDIA’s leading industrial marketing agency, we have customers of all sizes and capabilities. Our team has decades of experience working with engineers and manufacturers from start-ups to established, multi-national organisations. Our aim for all valued customers is to deliver tangible results and to exceed expectations. We tailor our tried, tested and proven industrial marketing strategies to suit our customers’ requirements for maximum results and enquiries. </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row flex">
            <div className="col-lg-3 col-md-6 p-0">
              <NavLink className="text-decoration-none" to="/customwebsitedesign">
                <div className="card">
                  <img src={require("../img/2col1.png")} className='' alt="" />
                  <div className="intro">
                    <h1>WEBSITE DESIGN</h1>
                    <h6>We have a proven track record designing affordable, custom-built and responsive industrial websites that generate enquiries</h6>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-3 col-md-6 p-0">
              <NavLink className="text-decoration-none" to="/seo">
                <div className="card">
                  <img src={require("../img/2col2.png")} className='' alt="" />
                  <div className="intro">
                    <h1>SEO</h1>
                    <h6>We are the UK's leading SEO service provider to manufacturing, engineering and industrial sectors, generating our clients enquiries online</h6>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-3 col-md-6 p-0">
              <NavLink className="text-decoration-none" to="/socialmedia">
                <div className="card">
                  <img src={require("../img/2col3.png")} className='' alt="" />
                  <div className="intro">
                    <h1>SOCIAL MEDIA</h1>
                    <h6>Our experienced team of technical content writers and social media experts implement compelling and informative articles for UK manufacturers.</h6>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-3 col-md-6 p-0">
              <NavLink className="text-decoration-none" to="/tracking">
                <div className="card">
                  <img src={require("../img/2col4.png")} className='' alt="" />
                  <div className="intro">
                    <h1>COMPANY TRACKING</h1>
                    <h6>We provide a sophisticated IP tracking software that identifies company names visiting your website - identify new prospects and understand how existing customers are navigating.</h6>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row flex">
            <div className="col-lg-3 col-md-6 p-0">
              <NavLink className="text-decoration-none" to="/companyvideo">
                <div className="card">
                  <img src={require("../img/2col5.png")} className='' alt="" />
                  <div className="intro">
                    <h1>VIDEO</h1>
                    <h6>Over 10 years visiting, filming and understanding many manufacturing processes. Using the latest 4K cameras, we will display your capabilities affordably and professionally. </h6>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-3 col-md-6 p-0">
              <NavLink className="text-decoration-none" to="/creativedesign">
                <div className="card">
                  <img src={require("../img/2col6.png")} className='' alt="" />
                  <div className="intro">
                    <h1>CREATING DESIGN</h1>
                    <h6>Dedicated to manufacturing, our creative designers will take your concepts and develop them into an impressive end product that captures your target market.</h6>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-3 col-md-6 p-0">
              <NavLink className="text-decoration-none" to="/ppc">
                <div className="card">
                  <img src={require("../img/2col7.png")} className='' alt="" />
                  <div className="intro">
                    <h1>PAID ADS MANAGEMENT</h1>
                    <h6>As Google partners, AMI offers full Ad management that complements organic rankings. We manage highly effective industrial campaigns on affordable budgets, set up to increase quality enquiries.</h6>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-3 col-md-6 p-0">
              <NavLink className="text-decoration-none" to="/contentwriting">
                <div className="card">
                  <img src={require("../img/2col8.png")} className='' alt="" />
                  <div className="intro">
                    <h1>CONTENT WRITING</h1>
                    <h6>Our team consists of experienced copywriters who have obtained a wealth of in-depth knowledge within the engineering and manufacturing sectors.</h6>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/test.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }}>
          <div className="container pt-5 pb-4">
            <div className='row ROW'>
              <div className='p-3' style={{ width: "18.75rem", color: "#fff", fontWeight: "700", border: "1.25rem solid #11b6e4", borderImage: "linear-gradient(45deg,#11b6e4,#11b6e4 3.75rem,transparent 3.75rem,transparent 5rem,#11b6e4 3.75rem) 12" }} >
                <h2 className='' style={{ fontSize: "1.225rem" }} >As a fast-growing precision engineering company, we needed a marketing agency who had expertise in working with engineers and could handle all aspects of our marketing</h2>
              </div>
              <div className='mt-3 ROW1' style={{ lineHeight: "1" }} >
                <h2 className='' style={{ fontSize: "1.225rem", color: "#11b6e4", fontWeight: "700", lineHeight: ".8" }} >Nick Furno, MD</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid d-none d-sm-block" style={{ borderTop: "0.375rem solid #11b6e4" }} >
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-8">
              <div className="p-lg-5  FontFAMIlyMontSerret ">
                <h2 className='ps-lg-5 ms-lg-4' style={{ color: "#11b6e4", fontSize: "2.5rem", fontWeight: "800" }} >SUPPORTING INDIA MANUFACTURING</h2>
                <p className='ps-lg-5 ms-lg-4'>Dedicated to maximising exposure for INDIA manufacturing companies</p>
                <p className='ps-lg-5 ms-lg-4'>AMI – Marketing for Manufacturing is trusted by INDIA manufacturers, engineers and industrial businesses. We have a reputation for delivering affordable, results-driven marketing with customers attracting regular new business enquiries.</p>
              </div>
            </div>

            <div className="col-lg-4 p-0">
              <img className='w-100 ' src={require("../img/last1.png")} alt="emp1" title='emp-1' />
            </div>
          </div>
        </div>

        <div className="container-fluid d-sm-none d-block" style={{ borderTop: "0.375rem solid #11b6e4" }} >
          <div className="row pt-2 d-flex justify-content-center align-items-center">
            <div className="col-lg-8">
              <div className="p-lg-5  FontFAMIlyMontSerret ">
                <h2 className='ps-lg-5 ms-lg-4' style={{ color: "#11b6e4", fontSize: "2.5rem", fontWeight: "800" }} >SUPPORTING INDIA MANUFACTURING</h2>
                <p className='ps-lg-5 ms-lg-4'>Dedicated to maximising exposure for INDIA manufacturing companies</p>
                <p className='ps-lg-5 ms-lg-4'>AMI – Marketing for Manufacturing is trusted by INDIA manufacturers, engineers and industrial businesses. We have a reputation for delivering affordable, results-driven marketing with customers attracting regular new business enquiries.</p>
              </div>
            </div>

            <div className="col-lg-4 p-0">
              <img className='w-100 ' src={require("../img/last1RESPONSIVE.png")} alt="emp1" title='emp-1' />
            </div>
          </div>
        </div>
      </section>

      <Silder />

    </>
  )
}

export default Home