import React from 'react'
import "./LatestWebsite.css"

const LatestWebiste = () => {
  return (

    <>

      <section>
        <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/LatestWebsiteBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "50vh" }}>
          <div className="container pt-5 pb-4">
            <div className='row ps-lg-2'>
              <h2 className='text-white' style={{ fontSize: "45px" }} >LATEST WEBSITES</h2>
              <h2 className='' style={{ fontSize: "40px", color: "#11b6e4", fontWeight: "700", letterSpacing: "3px" }} > FOR UK MANUFACTURERS </h2>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid pb-lg-5" style={{ backgroundColor: "#000" }} >
          <div className="container-lg pb-lg-5">
            <div className="row mb-4 g-4 d-flex justify-content-center p-lg-2">
              <div className="col-lg-4 col-md-6 ">
                <div className="card">
                  <img src={require("../img/LatestWebsite1.png")} className='' alt="" />
                  <div className="intro">
                    <h1>Wessington Cryogenics</h1>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Custom Website Design
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      SEO, Tracking & Google Ads
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Corporate Video
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Social Media Marketing
                    </h4>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 ">
                <div className="card">
                  <img src={require("../img/LatestWebsite2.png")} className='' alt="" />
                  <div className="intro">
                    <h1>PMJ International</h1>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Ecommerce Website Design
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Google Ads Management
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      SEO
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Website Hosting
                    </h4>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="card">
                  <img src={require("../img/LatestWebsite3.png")} className='' alt="" />
                  <div className="intro">
                    <h1>Kernow Coatings</h1>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Website Design
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      SEO
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Google Ads
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Website Hosting
                    </h4>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 ">
                <div className="card">
                  <img src={require("../img/LatestWebsite4.png")} className='' alt="" />
                  <div className="intro">
                    <h1>MJ Sections</h1>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Custom Website Design
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Corporate Video
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      10 x Feature Videos
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      SEO & Tracking
                    </h4>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 ">
                <div className="card">
                  <img src={require("../img/LatestWebsite5.png")} className='' alt="" />
                  <div className="intro">
                    <h1>Nationwide Lockers</h1>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Website Design
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Hosting
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Social Media
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Email Marketing
                    </h4>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 ">
                <div className="card">
                  <img src={require("../img/LatestWebsite6.png")} className='' alt="" />
                  <div className="intro">
                    <h1>Stroud Metal</h1>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Website Design
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Photography
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      SEO
                    </h4>
                    <h4>
                      <i class="fa-solid fa-angle-right me-2" style={{ color: "rgb(17, 182, 228)", fontSize: '20px', fontWeight: "800" }} ></i>
                      Social Media
                    </h4>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default LatestWebiste