import React from 'react'
import { NavLink } from 'react-router-dom'
import Silder from './Silder'

const SocialMediaVideo = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/promVideoBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "50vh" }}>
                    <div className="container pt-5 pb-4">
                        <div className='row ps-lg-2 mt-lg-5'>
                            <h2 className='text-white' style={{ fontSize: "45px" }} >SOCIAL MEDIA VIDEOS</h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-0" style={{ backgroundColor: "black" }} >
                    <div className="container-lg p-lg-3">
                        <div className="row p-lg-2 pt-4 mt-lg-5 d-flex justify-content-lg-center align-items-center FontFamilyMontSerret">
                            <div className="col-lg-8 col-md-8">
                                <div className=''>
                                    <h2 className='pb-2' style={{ fontSize: "2.3rem", color: "#fff", fontWeight: "700" }} >CAPTURE YOUR AUDIENCE ONLINE</h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.15rem", color: "#11b6e4", fontWeight: "700", letterSpacing: "1px" }} > Video specifically designed for Twitter, LinkedIn, Facebook and Instagram.</h3>
                                    <p className='pb-2 mt-5' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > AMI has a team of experienced social media video producers who are able to storyboard, script and create a varied range of promotional video that complements all of the recognised social media channels. Video for Facebook, LinkedIn, Twitter and Instagram has become a vital piece of marketing for all types of business.</p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > Affordable, but very professional social media video can be provided to capture a prospect’s attention, giving them a snapshot of your business whilst encouraging them to journey further into your offering. Our post-production team can also take existing footage and edit it down into short, snappy, promo material which is ideal for focusing on particular products, services and capabilities. </p>

                                </div>

                                <div className='row  g-4 pb-4 mt-4 pt-4 '>
                                    <div className='col-lg-5 col-md-6'>
                                        <NavLink className='' to="/videowithphotography">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3 ' style={{ letterSpacing: "1px" }} > VIDEO WITH PHOTOGRAPHY </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-3 col-md-6'>
                                        <NavLink className='' to="/socialmedia">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > SOCIAL MEDIA </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='row d-flex justify-content-around align-items-center FontFamilyMontSerret'>
                                    <div className='p-3' style={{ width: "18.75rem", color: "#fff", fontWeight: "700", border: "1.25rem solid #11b6e4", borderImage: "linear-gradient(45deg,#11b6e4,#11b6e4 3.75rem,transparent 3.75rem,transparent 5rem,#11b6e4 3.75rem) 12" }} >
                                        <h2 className='' style={{ fontSize: "1.02rem", lineHeight: "25px" }} >The flexibility of the service was also excellent and AMI were happy to support any bespoke requests. I would have no hesitations in recommending them for your video needs.</h2>
                                    </div>
                                    <div className='mt-3 text-center ps-lg-5 ms-lg-5' style={{ lineHeight: "1" }} >
                                        <h2 className='ps-lg-5' style={{ fontSize: "1rem", color: "#fff", fontWeight: "700", lineHeight: ".8" }} >Alex Dziurzynski</h2>
                                        <h2 className='ps-lg-5' style={{ fontSize: ".725rem", color: "#fff", fontWeight: "700", lineHeight: "1.3" }} >West End Precision</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container-fluid d-sm-none d-block " style={{ backgroundColor: "black" }} >
                    <div className="container p-lg-3 pb-lg-5">
                        <div className="row p-2 d-flex justify-content-center align-items-center">
                            <div className="col-lg-7 pt-lg-5 ">
                                <div className='pt-lg-5 mt-lg-5'>
                                    <h2 className='pb-2' style={{ fontSize: "2.675rem", color: "#fff" }} > INDIA'S LEADING INDUSTRIAL MARKETING MANAGEMENT AGENCY </h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700" }} >Promoting INDIA engineering, manufacturing & industrial companies</h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9475rem", color: "#fff", lineHeight: "1.375" }} >ICLOUD – Marketing for Manufacturing is the INDIA’s leading industrial marketing management agency specialising in helping manufacturers and engineers gain more enquiries online. Over the years the ICLOUD team has acquired a wealth of knowledge working with hundreds of industrial companies. We understand the most effective ways to market companies in the industrial sector – from affordable website development to promotional video and search marketing that delivers new business enquiries – and have an extensive track record and a growing base of very happy customers.</p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9575rem", color: "#fff", lineHeight: "1.375" }}>We are trusted by INDIA engineers, manufacturers and industrial businesses of all sizes and budgets, helping them to successfully promote their businesses online. We offer a comprehensive and affordable outsourced solution, with outstanding customer service that delivers on its promises. ICLOUD – Marketing for Manufacturing, your one-stop shop for all your industrial marketing needs.</p>
                                </div>

                                <div className='row g-4 pb-4 mt-3 pt-3'>
                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > INSIDE ICLOUD </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > TESTIMONIALS </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > LATEST WEBSITES </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block" style={{ backgroundColor: "black" }} >
                    <div className=" p-lg-3 ">
                        <div className="row mb-5 d-flex justify-content-between align-items-center FontFamilyMontSerret">
                            <div className="col-lg-6">
                                <div className=' mt-lg-5 ps-lg-5 ms-lg-5'>
                                    <h2 className='pb-2 FontFamilyMontSerret' style={{ fontSize: "2.275rem", color: "#fff", fontWeight: "800", letterSpacing: "2px" }} > CASE STUDY </h2>
                                    <h3 className='mt-2 pb-2 FontFamilyMontSerret' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700", letterSpacing: "1px" }} > Camberley Rubber Social Media Video - Case Study </h3>
                                    <p className='pb-2 mt-4' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > A full corporate video was produced for Camberley Rubber, highlighting the values of the company, its rich history and superb manufacturing facility. As part of our marketing package, we set about producing a number of short social media videos to help promote individual areas of the business. These videos are regularly used across its social media platforms and help reinforce the company name and the brand online. </p>
                                    <div className='pt-lg-4'>
                                        <NavLink className='d-flex justify-content-start align-items-center text-decoration-none' to="/contact">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3 ' style={{ letterSpacing: "1px" }} > CONTACT US </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-5 ">
                                <div className=''>
                                    <video controls className='w-100'>
                                        <source src={require("../img/video3.mp4")} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    {/* <video autoPlay loop muted className='w-100 ' style={{ objectFit: "fill" }}  >
                                        <source src={require("../img/video3.mp4")} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block" style={{ borderTop: "0.375rem solid #11b6e4" }} >
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-lg-8">
                            <div className="p-lg-5   ">
                                <h2 className='ps-lg-5 ms-lg-4 mb-4' style={{ color: "#11b6e4", fontSize: "2.8rem", fontWeight: "800" }} >SUPPORTING INDIA ENGINEERING</h2>
                                <p className='ps-lg-5 ms-lg-4 FONT' style={{ fontSize: "1.5rem", fontWeight: "600" }} >Dedicated to maximising exposure for UK Heavy & Large Part Machinists</p>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0">
                            <img className='w-100 ' style={{ height: "23rem" }} src={require("../img/last1.png")} alt="emp1" title='emp-1' />
                        </div>
                    </div>
                </div>
            </section>



            <Silder />

        </>
    )
}

export default SocialMediaVideo