import React from 'react'
import "./MeetTeam.css"
import { NavLink } from 'react-router-dom'

const MeetTeam = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/MeetTeamBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "50vh" }}>
                    <div className="container pt-5 pb-4">
                        <div className='row ps-lg-2'>
                            <h2 className='mt-lg-5' style={{ fontSize: "45px", color: "#11b6e4", fontWeight: "700", letterSpacing: "3px" }} > MEET THE TEAM </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-lg-5" style={{ backgroundColor: "#000" }} >
                    <div className="container p-lg-3 text-white text-center FontFamilyMontSerret">
                        <div className="row g-4">
                            <div className='mb-lg-3 pb-2'>
                                <h1> Sales & Marketing Enquiries / Management </h1>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam1.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Craig Pallister</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Managing Director</h5>
                                        <h5 className='contact-number mt-2'>01271 378 361</h5>
                                        <h5 className='contact-number' >07917 355825</h5>
                                    </div>
                                    <div className='mt-3'>
                                        <NavLink to='' target="_blank"><i className="fa-brands fa-linkedin fs-4 SocialIcon"></i> </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam2.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Lee Pallister</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Company Director</h5>
                                        <h5 className='contact-number mt-2'>01271 523 229</h5>
                                        <h5 className='contact-number' >07813 854499</h5>
                                    </div>
                                    <div className='mt-3'>
                                        <NavLink to='' target="_blank"><i className="fa-brands fa-linkedin fs-4 SocialIcon"></i> </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam3.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Ronnie Ellaway</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Communications Manager</h5>
                                        <h5 className='contact-number mt-2'>01271 379 614</h5>
                                        <h5 className='contact-number' >07811 593966</h5>
                                    </div>
                                    <div className='mt-3'>
                                        <NavLink to='' target="_blank"><i className="fa-brands fa-linkedin fs-4 SocialIcon"></i> </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam4.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Will Hunt</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Operations Manager</h5>
                                        <h5 className='contact-number mt-2'>01271 269 463</h5>
                                        <h5 className='contact-number' >07775 814644</h5>
                                    </div>
                                    <div className='mt-3'>
                                        <NavLink to='' target="_blank"><i className="fa-brands fa-linkedin fs-4 SocialIcon"></i> </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container p-lg-3 mt-lg-4 text-white text-center FontFamilyMontSerret">
                        <div className="row g-4">
                            <div className='mb-lg-3 pb-2'>
                                <h1> Web Design / Technical </h1>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam2-1.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >George Worthington</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Lead Web Developer</h5>
                                    </div>
                                    <div className='mt-3'>
                                        <NavLink to='' target="_blank"><i className="fa-brands fa-linkedin fs-4 SocialIcon"></i> </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam2-2.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Gareth Murby</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Senior Web Developer</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam2-3.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Paul Robinson</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Senior Web Developer</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam2-4.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Tom Holmes</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Web Developer</h5>
                                    </div>
                                    <div className='mt-3'>
                                        <NavLink to='' target="_blank"><i className="fa-brands fa-linkedin fs-4 SocialIcon"></i> </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container p-lg-3 mt-lg-3 text-white text-center FontFamilyMontSerret">
                        <div className="row d-flex justify-content-center g-5">
                            <div className='mb-lg-3 pb-2'>
                                <h1> Social Media </h1>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam3-1.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Lydia Wade</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Head of Social</h5>
                                    </div>
                                    <div className='mt-3'>
                                        <NavLink to='' target="_blank"><i className="fa-brands fa-linkedin fs-4 SocialIcon"></i> </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam3-2.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Daniel Knight</h5>
                                        <h5 className='pb-1' style={{ fontSize: ".965rem", color: "#11b6e4" }} >Social Media Writer & Photographer</h5>
                                    </div>
                                    <div className='mt-3'>
                                        <NavLink to='' target="_blank"><i className="fa-brands fa-linkedin fs-4 SocialIcon"></i> </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam3-3.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Simon Appleby</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Marketing Coordinator</h5>
                                    </div>
                                    <div className='mt-3'>
                                        <NavLink to='' target="_blank"><i className="fa-brands fa-linkedin fs-4 SocialIcon"></i> </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam3-4.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Georgia Sargeant</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Social Media Content Creator</h5>
                                    </div>
                                    <div className='mt-3'>
                                        <NavLink to='' target="_blank"><i className="fa-brands fa-linkedin fs-4 SocialIcon"></i> </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam3-5.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Erin Taylor</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Social Media Content Creator</h5>
                                    </div>
                                    <div className='mt-3'>
                                        <NavLink to='' target="_blank"><i className="fa-brands fa-linkedin fs-4 SocialIcon"></i> </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam3-6.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Niamh O’Brien</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Social Media Content Creator</h5>
                                    </div>
                                    <div className='mt-3'>
                                        <NavLink to='' target="_blank"><i className="fa-brands fa-linkedin fs-4 SocialIcon"></i> </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam3-7.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Will Evans</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Social Media Content Creator & Videographer</h5>
                                    </div>
                                    <div className='mt-3'>
                                        <NavLink to='' target="_blank"><i className="fa-brands fa-linkedin fs-4 SocialIcon"></i> </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container p-lg-3 mt-lg-4 text-white text-center FontFamilyMontSerret">
                        <div className="row d-flex justify-content-center g-4">
                            <div className='mb-lg-3 pb-2'>
                                <h1> Design & Video </h1>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam4-1.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Dean S</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Graphic Designer</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam4-2.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Tristan P</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Graphic Designer</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam4-3.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Tom H</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Videographer & Editor</h5>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="container p-lg-3 mt-lg-4 text-white text-center FontFamilyMontSerret">
                        <div className="row d-flex justify-content-center g-4">
                            <div className='mb-lg-3 pb-2'>
                                <h1> Accounts </h1>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam5-1.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Hayley</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Accounts & Bookkeeping</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mb-lg-5 pb-lg-5 p-lg-3 mt-lg-4 text-white text-center FontFamilyMontSerret">
                        <div className="row d-flex justify-content-center g-4">
                            <div className='mb-lg-3 pb-2'>
                                <h1> Security </h1>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam6-1.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Bruce</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Head of Security</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div>
                                    <img className='w-100' style={{ height: "12rem" }} src={require("../img/MeetTeam6-2.png")} alt="" />
                                    <div className='mt-lg-2 pt-2'>
                                        <h5 className='' style={{ fontSize: "1.125rem" }} >Ben</h5>
                                        <h5 className='pb-1' style={{ fontSize: "1rem", color: "#11b6e4" }} >Security Enforcement Officer</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default MeetTeam