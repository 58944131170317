import React from 'react'
import { NavLink } from 'react-router-dom'
import "./Components.css"
import Silder from './Silder'

const Components = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/BrandDevelopmentBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "50vh" }}>
                    <div className="container pt-5 pb-4">
                        <div className='row ps-lg-2'>
                            <h2 className='text-white' style={{ fontSize: "45px" }} >PRECISION</h2>
                            <h2 className='' style={{ fontSize: "40px", color: "#11b6e4", fontWeight: "700", letterSpacing: "3px" }} > COMPONENT PHOTOGRAPHY </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-0" style={{ backgroundColor: "black" }} >
                    <div className="container-lg p-lg-3">
                        <div className="row p-lg-2 pt-4 mt-lg-5 d-flex justify-content-lg-center align-items-center FontFamilyMontSerret">
                            <div className="col-lg-8 col-md-8">
                                <div className=''>
                                    <h2 className='pb-2' style={{ fontSize: "2.3rem", color: "#fff", fontWeight: "700" }} >SKILLED COMPONENT PHOTOGRAPHY</h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.15rem", color: "#11b6e4", fontWeight: "700", letterSpacing: "1px" }} > Specialist photography of low to high-volume components  </h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }} > With over 10 years experience working with all sizes of engineering and manufacturing companies, the key factor that remains constant no matter what size the business is, buyers are looking for the components you manufacture. The clearest way of displaying this online is with a comprehensive component gallery of your best work, NDA dependant! </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }}>  A professional component gallery will quickly distinguish the complexity, size, quality and volume of parts you are manufacturing, further pre-qualifying a prospect prior to enquiring. </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".8999rem", color: "#fff", lineHeight: "1.675" }}>  AMI has a dedicated in-house photographer and photography suite, kitted out with high-resolution 4K cameras, a range of back drops and professional lighting. The detail is also in the after-editing, ensuring the colour, detail and complexity of the part is brought to life. If you are manufacturing with anodised or plated components, coloured plastics, metals such as brass or copper and aluminium, our editing techniques are essential for high-quality shots. </p>

                                </div>

                                <div className='row  g-4 pb-4 mt-4 pt-4 '>
                                    <div className='col-lg-3 col-md-6'>
                                        <NavLink className='' to="/contact">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > CONTACT US </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-5 col-md-6'>
                                        <NavLink className='' to="/onlocation">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3 ' style={{ letterSpacing: "1px" }} > OnSITE PHOTOGRAPHY </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='row d-flex justify-content-around align-items-center FontFamilyMontSerret'>
                                    <div className='p-3' style={{ width: "18.75rem", color: "#fff", fontWeight: "700", border: "1.25rem solid #11b6e4", borderImage: "linear-gradient(45deg,#11b6e4,#11b6e4 3.75rem,transparent 3.75rem,transparent 5rem,#11b6e4 3.75rem) 12" }} >
                                        <h2 className='' style={{ fontSize: "1.02rem", lineHeight: "25px" }} >Great company, professional from start to finish. </h2>
                                    </div>
                                    <div className='mt-3 text-center ps-lg-5 ms-lg-5' style={{ lineHeight: "1" }} >
                                        <h2 className='ps-lg-5' style={{ fontSize: "1rem", color: "#fff", fontWeight: "700", lineHeight: ".8" }} >Tim Moss</h2>
                                        <h2 className='ps-lg-5' style={{ fontSize: ".725rem", color: "#fff", fontWeight: "700", lineHeight: "1.3" }} >M&M Turned Parts</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container-fluid d-sm-none d-block " style={{ backgroundColor: "black" }} >
                    <div className="container p-lg-3 pb-lg-5">
                        <div className="row p-2 d-flex justify-content-center align-items-center">
                            <div className="col-lg-7 pt-lg-5 ">
                                <div className='pt-lg-5 mt-lg-5'>
                                    <h2 className='pb-2' style={{ fontSize: "2.675rem", color: "#fff" }} > INDIA'S LEADING INDUSTRIAL MARKETING MANAGEMENT AGENCY </h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700" }} >Promoting INDIA engineering, manufacturing & industrial companies</h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9475rem", color: "#fff", lineHeight: "1.375" }} >ICLOUD – Marketing for Manufacturing is the INDIA’s leading industrial marketing management agency specialising in helping manufacturers and engineers gain more enquiries online. Over the years the ICLOUD team has acquired a wealth of knowledge working with hundreds of industrial companies. We understand the most effective ways to market companies in the industrial sector – from affordable website development to promotional video and search marketing that delivers new business enquiries – and have an extensive track record and a growing base of very happy customers.</p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9575rem", color: "#fff", lineHeight: "1.375" }}>We are trusted by INDIA engineers, manufacturers and industrial businesses of all sizes and budgets, helping them to successfully promote their businesses online. We offer a comprehensive and affordable outsourced solution, with outstanding customer service that delivers on its promises. ICLOUD – Marketing for Manufacturing, your one-stop shop for all your industrial marketing needs.</p>
                                </div>

                                <div className='row g-4 pb-4 mt-3 pt-3'>
                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > INSIDE ICLOUD </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > TESTIMONIALS </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > LATEST WEBSITES </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>

            <section>
                <div className="container-fluid" >
                    <div className="row">
                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/Components1.png")} className='imageCOMPONENTS' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/Components2.png")} className='imageCOMPONENTS' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/Components3.png")} className='imageCOMPONENTS' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/Components4.png")} className='imageCOMPONENTS' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/Components5.png")} className='imageCOMPONENTS' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/Components6.png")} className='imageCOMPONENTS' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/Components7.png")} className='imageCOMPONENTS' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/Components8.png")} className='imageCOMPONENTS' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/Components9.png")} className='imageCOMPONENTS' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/Components10.png")} className='imageCOMPONENTS' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/Components11.png")} className='imageCOMPONENTS' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/Components12.png")} className='imageCOMPONENTS' alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block" style={{ borderTop: "0.375rem solid #11b6e4" }} >
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-lg-8">
                            <div className="p-lg-5   ">
                                <h2 className='ps-lg-5 ms-lg-4 mb-4' style={{ color: "#11b6e4", fontSize: "2.8rem", fontWeight: "800" }} >SUPPORTING INDIA ENGINEERING</h2>
                                <p className='ps-lg-5 ms-lg-4 FONT' style={{ fontSize: "1.5rem", fontWeight: "600" }} >Dedicated to maximising exposure for UK Heavy & Large Part Machinists</p>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0">
                            <img className='w-100 ' style={{ height: "23rem" }} src={require("../img/last1.png")} alt="emp1" title='emp-1' />
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default Components