import React, { useRef, useState } from 'react'
import "./Contact.css"
import { NavLink } from 'react-router-dom';

const Contact = () => {

    const form = useRef();

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});


    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.phone = ""; errors.email = ""; errors.massage = "";

            var url = "https://wa.me/1271379270?text="
                + "NAME:" + name + "Phone:" + phone + "EMAIL:" + email + "MASSAGE:" + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
    }


    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }

        if (!phone) {
            errors.phone = "Number Is Requried";
        }
        else if (phone.length !== 10) {
            errors.phone = "Number Must Have 10 Digit";
        }

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Massage Is Requried";
        }
        return errors;
    }

    return (
        <>

            <section>
                <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/CarrersBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "50vh" }}>
                    <div className="container pt-5 pb-4">
                        <div className='row ps-lg-2'>
                            <h2 className='text-white mt-5' style={{ fontSize: "45px" }} >GET IN TOUCH</h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../img/ContactFormBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }} >
                    <div className="container p-lg-2  ">
                        <div className="row text-white mb-lg-5 pb-5  pt-5">

                            <div className="col-lg-12">
                                <div className="row d-flex justify-content-center  p-lg-3 Font_Alegreya">

                                    <div className="col-lg-6">
                                        <div className=''>
                                            <form ref={form} onSubmit={loginHandle} className="row g-4" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                                <div class="col-lg-12 ">
                                                    <label className='mb-2' style={{ fontSize: "16px", fontWeight: "600" }} htmlFor="">NAME</label>
                                                    <input type="text" className="form-control   text-dark UbuntuFONTFAMILY  " style={{ borderRadius: "0px 0px 0px 0px", color: "#777", backgroundColor: "#091f2c", border: "3px solid #11b6e4", height: "50px" }} id="inputName" name="name"
                                                        onChange={e => setName(e.target.value)} value={name}
                                                    />
                                                    {errors.name && <div className='text-danger'>{errors.name}</div>}
                                                </div>

                                                <div className='col-lg-12'>
                                                    <label className='mb-2' style={{ fontSize: "16px", fontWeight: "600" }} htmlFor="">EMAIL</label>
                                                    <input type="email" className="form-control boderRadius UbuntuFONTFAMILY" style={{ borderRadius: "0px 0px 0px 0px", color: "#777", backgroundColor: "#091f2c", border: "3px solid #11b6e4", height: "50px" }} id="inputEmail4" name="email"
                                                        onChange={e => setEmail(e.target.value)} value={email}
                                                    />
                                                    {errors.email && <div className='text-danger'>{errors.email}</div>}
                                                </div>

                                                <div class="col-lg-12 ">
                                                    <label className='mb-2' style={{ fontSize: "16px", fontWeight: "600" }} htmlFor="">PHONE NUMBER</label>
                                                    <input type="number" className="form-control text-white UbuntuFONTFAMILY" minLength='10' maxLength='10' style={{ borderRadius: "0px 0px 0px 0px", color: "#777", backgroundColor: "#091f2c", border: "3px solid #11b6e4", height: "50px" }} id="inputPhone" name="phone"
                                                        onChange={e => setPhone(e.target.value)} value={phone}
                                                    />
                                                    {errors.phone && <div className='text-danger'>{errors.phone}</div>}
                                                </div>

                                                <div className="col-lg-12">
                                                    <label className='mb-2' style={{ fontSize: "16px", fontWeight: "600" }} htmlFor="">ENQUIRY</label>
                                                    <textarea type="text" className="form-control   text-white UbuntuFONTFAMILY" style={{ borderRadius: "0px 0px 0px 0px", height: "155px", backgroundColor: "#091f2c", border: "3px solid #11b6e4" }} id="inputText3" name="message"
                                                        onChange={e => setMassage(e.target.value)} value={massage}
                                                    />
                                                    {errors.massage && <div className='text-danger'>{errors.massage}</div>}
                                                </div>

                                                <div className="col-lg-12 mt-lg-4 pt-4">
                                                    <button type="submit" className=" p-1 SendButton "  ><b className='' style={{ fontWeight: "600", fontSize: "18px" }}>SUBMIT</b></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 text-center">
                                        <div className='ms-lg-4 mt-5 pt-lg-5'>
                                            <p className=''> For over 10 years, AMI have been trusted by over 200 manufacturers across the UK. </p>
                                            <p className=''> As the UK’s leading manufacturing and engineering marketing agency, we have a a proven track record in delivering results for our customers. We believe that the true indicator for our customers success are increases in enquiries and business generation. </p>
                                            <p className=''> We offer a free, no obligation marketing audit. Get in touch with our knowledgeable team today to discuss your requirements. </p>
                                            <div className=''>
                                                <div className=''>
                                                    Email :<NavLink className='emailHover1 ' to="mailto:enquiry@amiweb.co.uk"> <span className='text-center fs-5 UbuntuFONTFAMILY'>enquiry@amiweb.co.uk</span></NavLink>
                                                </div>

                                                <div className='' style={{ fontSize: "18px", fontWeight: "300" }}>
                                                    Tel :<NavLink className="text-decoration-none  p-1" style={{ color: "#11b6e4" }} exact to="tel:1271 379270" ><b>+44 (0) 1271 379270</b></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='' style={{ backgroundImage: `url(${require("../img/map.png")})`, backgroundAttachment: "fixed", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "30.375rem" }} >
            </section>

        </>
    )
}

export default Contact