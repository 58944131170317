import React from 'react'
import "./WireErosion.css"
import { NavLink } from 'react-router-dom'
import Silder from './Silder'


const WireErosion = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/WireErosionBannerBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "50vh" }}>
                    <div className="container pt-5 pb-4">
                        <div className='row ps-lg-2'>
                            <h2 className='text-white' style={{ fontSize: "45px" }} >INDUSTRIES</h2>
                            <h2 className='' style={{ fontSize: "45px", color: "#11b6e4" }} >EDM WIRE & SPARK EROSION</h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-0 " style={{ backgroundColor: "black" }} >
                    <div className="container-lg p-lg-3">
                        <div className="row p-lg-2 pt-4 mt-lg-5 d-flex justify-content-lg-center align-items-center">
                            <div className="col-lg-8 col-md-8">
                                <div className=''>
                                    <h2 className='pb-2' style={{ fontSize: "2.6rem", color: "#fff" }} > MARKETING FOR PRECISION ENGINEERS </h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700" }} > Effective digital marketing to increase inbound subcontract enquiries  </h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9475rem", color: "#fff", lineHeight: "1.375" }} > The UK in recent years has seen a surge in the demand for highly accurate complex components required at competitive prices. Engineers investing in wire and spark EDM machining, along with 5-axis capabilities, are stepping up to the plate in advancing UK manufacturing.</p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9575rem", color: "#fff", lineHeight: "1.375" }}> As the demand for highly intricate machined parts for diverse and hard materials increases, so does the demand for wire and spark EDM machining grow. Over the years AMI has strategically promoted UK engineering companies with EDM technology via effective search engine optimisation, PPC (pay per click) advertising, specialist photography, video and social media. </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9575rem", color: "#fff", lineHeight: "1.375" }}> If you’re a company that has invested in EDM or complex machining technology, do contact us. Our knowledgeable team of marketing specialists are happy to discuss your requirements at no obligation. </p>
                                </div>

                                <div className='row  g-4 pb-4 mt-3 pt-3'>
                                    <div className='col-lg-3 col-md-6'>
                                        <NavLink className='' to="/websitemanagement">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > WEBSITES </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-3 col-md-6'>
                                        <NavLink className='' to="/contact">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > CONTACT US </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='row d-flex justify-content-around align-items-center FontFamilyMontSerret'>
                                    <div className='p-3' style={{ width: "18.75rem", color: "#fff", fontWeight: "700", border: "1.25rem solid #11b6e4", borderImage: "linear-gradient(45deg,#11b6e4,#11b6e4 3.75rem,transparent 3.75rem,transparent 5rem,#11b6e4 3.75rem) 12" }} >
                                        <h2 className='' style={{ fontSize: "1.02rem", lineHeight: "25px" }} > I would highly recommend using AMI for web and video work and as a complete online solution in generating new business. </h2>
                                    </div>
                                    <div className='mt-3 text-center ps-lg-5 ms-lg-5' style={{ lineHeight: "1" }} >
                                        <h2 className='ps-lg-5' style={{ fontSize: "1rem", color: "#fff", fontWeight: "700", lineHeight: ".8" }} >Mark Sendry</h2>
                                        <h2 className='ps-lg-5' style={{ fontSize: ".725rem", color: "#fff", fontWeight: "700", lineHeight: "1.3" }} >Metrics Engineering</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container-fluid d-sm-none d-block " style={{ backgroundColor: "black" }} >
                    <div className="container p-lg-3 pb-lg-5">
                        <div className="row p-2 d-flex justify-content-center align-items-center">
                            <div className="col-lg-7 pt-lg-5 ">
                                <div className='pt-lg-5 mt-lg-5'>
                                    <h2 className='pb-2' style={{ fontSize: "2.675rem", color: "#fff" }} > INDIA'S LEADING INDUSTRIAL MARKETING MANAGEMENT AGENCY </h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700" }} >Promoting INDIA engineering, manufacturing & industrial companies</h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9475rem", color: "#fff", lineHeight: "1.375" }} >ICLOUD – Marketing for Manufacturing is the INDIA’s leading industrial marketing management agency specialising in helping manufacturers and engineers gain more enquiries online. Over the years the ICLOUD team has acquired a wealth of knowledge working with hundreds of industrial companies. We understand the most effective ways to market companies in the industrial sector – from affordable website development to promotional video and search marketing that delivers new business enquiries – and have an extensive track record and a growing base of very happy customers.</p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9575rem", color: "#fff", lineHeight: "1.375" }}>We are trusted by INDIA engineers, manufacturers and industrial businesses of all sizes and budgets, helping them to successfully promote their businesses online. We offer a comprehensive and affordable outsourced solution, with outstanding customer service that delivers on its promises. ICLOUD – Marketing for Manufacturing, your one-stop shop for all your industrial marketing needs.</p>
                                </div>

                                <div className='row g-4 pb-4 mt-3 pt-3'>
                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > INSIDE ICLOUD </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > TESTIMONIALS </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > LATEST WEBSITES </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block" style={{ backgroundColor: "black" }} >
                    <div className=" p-lg-3 ">
                        <div className="row  d-flex justify-content-between align-items-center">
                            <div className="col-lg-7">
                                <div className=' mt-lg-5 ps-lg-5 ms-lg-5'>
                                    <h2 className='pb-2' style={{ fontSize: "2.575rem", color: "#fff" }} > CLIENT FOCUS </h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700" }} > Erodatools Ltd</h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: "1.1rem", color: "#fff", lineHeight: "1.375" }} >Erodatools Ltd has been a specialist in the field of EDM (electrical discharge machining) wire erosion and spark erosion for over 40 years. </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: "1.1rem", color: "#fff", lineHeight: "1.375" }} >Well known throughout all areas of industry and ISO 9001 2008 certified, it provides precision engineering services to businesses across the UK and has at its disposal a full range of modern EDM machining capabilities.</p>

                                    <div className='mb-1'>
                                        <i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i>
                                        <span className='ps-2 CLIENTFOCUS' style={{ fontSize: ".9999rem", letterSpacing: "1px", fontWeight: "400" }}>SEO</span>
                                    </div>
                                    <div className='mb-1'>
                                        <i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i>
                                        <span className='ps-2 CLIENTFOCUS' style={{ fontSize: ".9999rem", letterSpacing: "1px", fontWeight: "400" }}>Social Media</span>
                                    </div>
                                    <div className='mb-1'>
                                        <i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i>
                                        <span className='ps-2 CLIENTFOCUS' style={{ fontSize: ".9999rem", letterSpacing: "1px", fontWeight: "400" }}>Video with Photography</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <img className='w-100' style={{ height: "25rem" }} src={require("../img/WireErosionBanner1.png")} alt="emp1" title='emp-1' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" >
                    <div className="row">
                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/WireErosionBanner2.png")} className='imageWIRE' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/WireErosionBanner3.png")} className='imageWIRE' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/WireErosionBanner4.png")} className='imageWIRE' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/WireErosionBanner5.png")} className='imageWIRE' alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block" style={{ borderTop: "0.375rem solid #11b6e4" }} >
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-lg-8">
                            <div className="p-lg-5  FontFICLOUDlyMontSerret ">
                                <h2 className='ps-lg-5 ms-lg-4' style={{ color: "#11b6e4", fontSize: "2.8rem", fontWeight: "800" }} >SUPPORTING INDIA ENGINEERING</h2>
                                <p className='ps-lg-5 ms-lg-4 FONT' style={{ fontSize: "1.5rem", fontWeight: "600" }} >Dedicated to maximising exposure for Wire & Spark Erosion companies</p>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0">
                            <img className='w-100 ' src={require("../img/last1.png")} alt="emp1" title='emp-1' />
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default WireErosion