import React from 'react'
import { NavLink } from 'react-router-dom'

const PrivacyPolicy = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/TearmUseBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "30vh" }}>
                    <div className="container pt-5 pb-4">
                        <div className='row ps-lg-5 ms-lg-3'>
                            <h2 className='mt-lg-3 ms-lg-5' style={{ fontSize: "43px", color: "#11b6e4", fontWeight: "700", letterSpacing: "3px" }} > PRIVACY POLICY </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid pb-lg-5" style={{ backgroundColor: "#000" }} >
                    <div className="container-lg p-lg-3 pt-3 text-white">
                        <div className="row d-flex justify-content-center p-lg-4 FontFamilyMontSerret">
                            <div className='col-lg-10'  >
                                <div className='' style={{ fontSize: ".8975rem" }} >
                                    <p> Aspect Media International Ltd (“We”) are committed to protecting and respecting your privacy. </p>
                                    <p> This policy (together with our terms of use https://www.amiweb.co.uk/terms-of-use/ and any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By visiting https://www.amiweb.co.uk you are accepting and consenting to the practices described in this policy. </p>
                                    <p> For the purpose of the Data Protection Act 1998 (the Act), the data controller is Aspect Media International of Suite 210 Queens House, Queens Street, Barnstaple, Devon, EX32 8HJ. </p>
                                    <p className='mb-lg-4 mt-lg-4' style={{ fontSize: "20px", fontWeight: "800" }} > Information we may collect from you</p>
                                    <p className='mb-lg-5'> We may collect and process the following data about you:</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; <b> Information you give us. </b> You may give us information about you by filling in forms on our site https://www.amiweb.co.uk/contact-us/ or by corresponding with us by phone, e-mail or otherwise. This includes the recording of phone calls, information you provide when you enquire about our services,, participate in discussion boards or other social media functions on our site, enter a competition, promotion or survey and when you report a problem with our site. The information you give us may include your name, business name, business address, e-mail address and phone number, financial and credit card information, personal description and photograph.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; <b> Information we collect about you. </b> With regard to each of your visits to our site we may automatically collect the following information:</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, company name, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and from our site (including date and time); products you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call our customer service number.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; <b> Information we receive from other sources. </b> We may receive information about you if you use any of the other websites we operate or the other services we provide. We are also working closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers, credit reference agencies) and may receive information about you from them.</p>
                                    <p className='mb-lg-4 mt-lg-4' style={{ fontSize: "20px", fontWeight: "800" }} > Cookies </p>
                                    <p> Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. For detailed information on the cookies we use and the purposes for which we use them see our Cookie policy https://www.amiweb.co.uk/cookie-policy/</p>
                                    <p className='mb-lg-4 mt-lg-4' style={{ fontSize: "20px", fontWeight: "800" }} > Uses made of the information </p>
                                    <p> We use information held about you in the following ways: </p>

                                    <p className='mb-lg-4 mt-lg-4' style={{ fontSize: "20px", fontWeight: "800" }} > Information you give to us. </p>
                                    <p> We will use this information: </p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; to carry out our obligations arising from any contracts entered into between you and us and to provide you with the information, products and services that you request from us;</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; to provide you with information about other goods and services we offer that are similar to those that you have already purchased or enquired about;</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; to notify you about changes to our service;</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; to ensure that content from our site is presented in the most effective manner for you and for your computer.</p>

                                    <p className='mb-lg-4 mt-lg-4' style={{ fontSize: "20px", fontWeight: "800" }} > Information we collect about you. </p>
                                    <p> We will use this information </p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; to administer our site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; to improve our site to ensure that content is presented in the most effective manner for you and for your computer;</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; to allow you to participate in interactive features of our service, when you choose to do so;</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; as part of our efforts to keep our site safe and secure;</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; to measure or understand the effectiveness of content we serve to you and others, and to deliver relevant content to you;</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; to make suggestions and recommendations to you and other users of our site about goods or services that may interest you or them.</p>

                                    <p className='mb-lg-4 mt-lg-4' style={{ fontSize: "20px", fontWeight: "800" }} > Information we receive from other sources. </p>
                                    <p> We may combine this information with information you give to us and information we collect about you. We may us this information and the combined information for the purposes set out above (depending on the types of information we receive). </p>

                                    <p className='mb-lg-4 mt-lg-4' style={{ fontSize: "20px", fontWeight: "800" }} > Disclosure of your information </p>
                                    <p> We may share your personal information with any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the UK Companies Act 2006. </p>

                                    <p> We may share your information with selected third parties including: </p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Business partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Advertisers and advertising networks that require the data to select and serve relevant adverts to you and others.  We do not disclose information about identifiable individuals to our advertisers, but we may provide them with aggregate information about our users (for example, we may inform them that 500 men aged under 30 have clicked on their advertisement on any given day). We may also use such aggregate information to help advertisers reach the kind of audience they want to target (for example, women in SW1). We may make use of the personal data we have collected from you to enable us to comply with our advertisers’ wishes by displaying their advertisement to that target audience.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Analytics and search engine providers that assist us in the improvement and optimisation of our site.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; Credit reference agencies for the purpose of assessing your credit score where this is a condition of us entering into a contract with you.</p>

                                    <p> We may disclose your personal information to third parties: </p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; If Aspect Media International Ltd or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets.</p>
                                    <p> <i class="fa-sharp fa-solid fa-angle-right" style={{ color: "#11b6e4", fontSize: "14px" }} ></i> &nbsp; If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use https://www.amiweb.co.uk/terms-of-use/</p>

                                    <p className='mb-lg-2 mt-lg-2' style={{ fontSize: "20px", fontWeight: "800" }} > Where we store your personal data </p>
                                    <p> The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area (“EEA”). It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers. Such staff maybe engaged in, among other things, the fulfilment of your order, the processing of your payment details and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy. </p>
                                    <p> All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted using SSL technology. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone. </p>
                                    <p> Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access. </p>

                                    <p className='mb-lg-2 mt-lg-2' style={{ fontSize: "20px", fontWeight: "800" }} > Your rights </p>
                                    <p> You have the right to ask us not to process your personal data for marketing purposes. We will usually inform you (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose your information to any third party for such purposes. You can exercise your right to prevent such processing by checking certain boxes on the forms we use to collect your data.  You can also exercise the right at any time by contacting us at admin@amiweb.co.uk </p>
                                    <p> Our site may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates.  If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies.  Please check these policies before you submit any personal data to these websites. </p>

                                    <p className='mb-lg-2 mt-lg-2' style={{ fontSize: "20px", fontWeight: "800" }} > Access to information </p>
                                    <p> The Act gives you the right to access information held about you. Your right of access can be exercised in accordance with the Act </p>

                                    <p className='mb-lg-2 mt-lg-2' style={{ fontSize: "20px", fontWeight: "800" }} > Changes to our privacy policy </p>
                                    <p> Any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes to our privacy policy. </p>

                                    <p className='mb-lg-2 mt-lg-2' style={{ fontSize: "20px", fontWeight: "800" }} > Contact </p>
                                    <p> Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to admin@amiweb.co.uk </p>

                                </div>

                                <div className=' mt-lg-4 pt-lg-4'>
                                    <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                        <button className=' ReadMoreButton' style={{ fontSize: "16px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > CHANGE COOKIE PREFERENCES </b> </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default PrivacyPolicy