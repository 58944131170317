import React from 'react'
import "./CncMachine.css"
import { NavLink } from 'react-router-dom'
import Silder from './Silder'

const CncMachine = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 LAST1IMG" style={{ backgroundImage: `url(${require("../img/CncBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "50vh" }}>
                    <div className="container pt-5 pb-4">
                        <div className='row ps-lg-2'>
                            <h2 className='text-white' style={{ fontSize: "45px" }} >INDUSTRIES</h2>
                            <h2 className='' style={{ fontSize: "45px", color: "#11b6e4" }} >PRECISION CNC MACHINING</h2>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-0 " style={{ backgroundColor: "black" }} >
                    <div className="container-lg p-lg-3">
                        <div className="row p-lg-2 pt-4 mt-lg-5 d-flex justify-content-lg-center align-items-center">
                            <div className="col-lg-8 col-md-8">
                                <div className=''>
                                    <h2 className='pb-2' style={{ fontSize: "2.6rem", color: "#fff" }} > DO YOU HAVE SPARE CAPACITY? </h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700" }} > We are technical marketing experts for subcontract CNC machinists  </h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9475rem", color: "#fff", lineHeight: "1.375" }} > We work with 80+ UK engineering companies with CNC machining capabilities and we ensure our marketing campaigns are targeted to generate high-quality enquiries in desired target markets. We work with Tier 1, 2 and 3 subcontractors who regularly supply to demanding industries such as aerospace, nuclear, defence, Formula 1, marine and space. Our customers are continually investing in the latest machinery to keep up with demand for fast turnaround machining, utilising some of the latest Mazak, Matsuura, DMG Mori, Doosan and Haas machinery.</p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9575rem", color: "#fff", lineHeight: "1.375" }}> We design and build websites to the highest standards, reflecting the quality of our clients’ machining capabilities. We strongly recommend promotional video and photography to all prospective clients with advanced CNC machining capabilities as visual aids strongly instil credibility in the business and allow prospects to understand how you work. </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9575rem", color: "#fff", lineHeight: "1.375" }}> If you are looking at modernising your online presence or looking to fill spare capacity, get in touch today – it is what we do. </p>
                                </div>

                                <div className='row  g-4 pb-4 mt-3 pt-3'>
                                    <div className='col-lg-3 col-md-6'>
                                        <NavLink className='' to="/websitemanagement">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > WEBSITES </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-3 col-md-6'>
                                        <NavLink className='' to="/contact">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > CONTACT US </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='row d-flex justify-content-around align-items-center FontFamilyMontSerret'>
                                    <div className='p-3' style={{ width: "18.75rem", color: "#fff", fontWeight: "700", border: "1.25rem solid #11b6e4", borderImage: "linear-gradient(45deg,#11b6e4,#11b6e4 3.75rem,transparent 3.75rem,transparent 5rem,#11b6e4 3.75rem) 12" }} >
                                        <h2 className='' style={{ fontSize: "1.02rem", lineHeight: "25px" }} > ICLOUDSOFT have delivered on everything initially promised. Since the website launched we have received an excellent enquiry which has turned into an order for a core part of our business. </h2>
                                    </div>
                                    <div className='mt-3 text-center ps-lg-5 ms-lg-5' style={{ lineHeight: "1" }} >
                                        <h2 className='ps-lg-5' style={{ fontSize: "1rem", color: "#fff", fontWeight: "700", lineHeight: ".8" }} >Mark Sendry</h2>
                                        <h2 className='ps-lg-5' style={{ fontSize: ".725rem", color: "#fff", fontWeight: "700", lineHeight: "1.3" }} >Metrics Engineering</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container-fluid d-sm-none d-block " style={{ backgroundColor: "black" }} >
                    <div className="container p-lg-3 pb-lg-5">
                        <div className="row p-2 d-flex justify-content-center align-items-center">
                            <div className="col-lg-7 pt-lg-5 ">
                                <div className='pt-lg-5 mt-lg-5'>
                                    <h2 className='pb-2' style={{ fontSize: "2.675rem", color: "#fff" }} > INDIA'S LEADING INDUSTRIAL MARKETING MANAGEMENT AGENCY </h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700" }} >Promoting INDIA engineering, manufacturing & industrial companies</h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9475rem", color: "#fff", lineHeight: "1.375" }} >ICLOUD – Marketing for Manufacturing is the INDIA’s leading industrial marketing management agency specialising in helping manufacturers and engineers gain more enquiries online. Over the years the ICLOUD team has acquired a wealth of knowledge working with hundreds of industrial companies. We understand the most effective ways to market companies in the industrial sector – from affordable website development to promotional video and search marketing that delivers new business enquiries – and have an extensive track record and a growing base of very happy customers.</p>
                                    <p className='pb-2 mt-3' style={{ fontSize: ".9575rem", color: "#fff", lineHeight: "1.375" }}>We are trusted by INDIA engineers, manufacturers and industrial businesses of all sizes and budgets, helping them to successfully promote their businesses online. We offer a comprehensive and affordable outsourced solution, with outstanding customer service that delivers on its promises. ICLOUD – Marketing for Manufacturing, your one-stop shop for all your industrial marketing needs.</p>
                                </div>

                                <div className='row g-4 pb-4 mt-3 pt-3'>
                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > INSIDE ICLOUD </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > TESTIMONIALS </b> </button>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-4 col-md-6'>
                                        <NavLink className='' to="https://www.icloudsoftware.co.in/">
                                            <button className=' ReadMoreButton' style={{ fontSize: "14px", borderRadius: '2px' }} > <b className='p-3' style={{ letterSpacing: "1px" }} > LATEST WEBSITES </b> </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block" style={{ backgroundColor: "black" }} >
                    <div className=" p-lg-3 ">
                        <div className="row p-2 mb-lg-5 d-flex justify-content-between align-items-center">
                            <div className="col-lg-7">
                                <div className=' mt-lg-5 ps-lg-5 ms-lg-5'>
                                    <h2 className='pb-2' style={{ fontSize: "2.575rem", color: "#fff" }} > CLIENT FOCUS </h2>
                                    <h3 className='mt-2 pb-2' style={{ fontSize: "1.25rem", color: "#11b6e4", fontWeight: "700" }} >Metaltech Precision</h3>
                                    <p className='pb-2 mt-3' style={{ fontSize: "1.1rem", color: "#fff", lineHeight: "1.375" }} >Metaltech’s history goes back to 1973, offering total manufacturing on site as its increasing and extensive plant list illustrates, including: CNC turning, CNC milling and 5-axis machining all coupled with automated loading systems. </p>
                                    <p className='pb-2 mt-3' style={{ fontSize: "1.1rem", color: "#fff", lineHeight: "1.375" }} >Alongside this, grinding, fabricating, shotblasting, painting, assembly and testing are all used to back up this service and offer its customers a complete solution. It can machine high-precision 3mm diameter parts right up to coping with a 5-tonne fabrication job under the same roof! </p>

                                    <div className='mb-1'>
                                        <i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i>
                                        <span className='ps-2 CLIENTFOCUS' style={{ fontSize: ".9999rem", letterSpacing: "1px" }}>Website Design</span>
                                    </div>

                                    <div className='mb-1'>
                                        <i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i>
                                        <span className='ps-2 CLIENTFOCUS' style={{ fontSize: ".9999rem", letterSpacing: "1px" }}>SEO</span>
                                    </div>
                                    <div className='mb-1'>
                                        <i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i>
                                        <span className='ps-2 CLIENTFOCUS' style={{ fontSize: ".9999rem", letterSpacing: "1px" }}>Social Media</span>
                                    </div>
                                    <div className='mb-1'>
                                        <i class="fa-sharp fa-solid fa-angle-right " style={{ color: "#11b6e4" }} ></i>
                                        <span className='ps-2 CLIENTFOCUS' style={{ fontSize: ".9999rem", letterSpacing: "1px" }}>Video with Photography</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <img className='w-100' style={{ height: "25rem" }} src={require("../img/cnc1.png")} alt="emp1" title='emp-1' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" >
                    <div className="row">
                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/cnc2.png")} className='imageCNC' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/cnc3.png")} className='imageCNC' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/cnc4.png")} className='imageCNC' alt="" />
                        </div>

                        <div className="col-lg-3 col-md-6 p-0">
                            <img src={require("../img/cnc5.png")} className='imageCNC' alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block" style={{ borderTop: "0.375rem solid #11b6e4" }} >
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-lg-8">
                            <div className="p-lg-5  FontFICLOUDlyMontSerret ">
                                <h2 className='ps-lg-5 ms-lg-4' style={{ color: "#11b6e4", fontSize: "2.8rem", fontWeight: "800" }} >SUPPORTING INDIA ENGINEERING</h2>
                                <p className='ps-lg-5 ms-lg-4' style={{ fontSize: "1.5rem", fontWeight: "600" }} >Dedicated to maximising exposure for UK Precision Engineers</p>
                            </div>
                        </div>

                        <div className="col-lg-4 p-0">
                            <img className='w-100 ' src={require("../img/last1.png")} alt="emp1" title='emp-1' />
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default CncMachine